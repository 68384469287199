import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import useNavigate for navigation
import styles from "./Header.module.css";
import SearchBar from "./SearchBar/SearchBar"; // Import the SearchBar component
const Header = ({ onCollapseToggle }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate(); // useNavigate hook for navigation

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = (e, path) => {
    e.preventDefault(); // Prevent full-page reload
    setIsDropdownOpen(false); // Close dropdown
    navigate(path); // Navigate programmatically
  };

  return (
    <div className={styles.header}>
      <div className={styles["header-title"]}>
        <button
          className={styles["collapse-btn"]}
          onClick={onCollapseToggle}
          aria-label="Toggle menu"
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <Link to="/">
          <img
            src={window.logoUrl}
            alt="Earfy Logo"
            className={styles["header-logo"]}
          />
        </Link>

        <div className={styles["title-with-dropdown"]}>
          <span className={styles["fancy-title-span"]}>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              EARF
            </Link>
          </span>

          <span className={styles.BETA}>BETA</span>

          <button
            className={styles["vertical-dots-btn"]}
            onClick={toggleDropdown}
            aria-label="Open dropdown menu"
          >
            <i className="fa-solid fa-ellipsis-v"></i>
          </button>

          {isDropdownOpen && (
            <ul className={styles["dropdown-menu"]}>
              <li>
                <a
                  href="/camera-request"
                  onClick={(e) => handleLinkClick(e, "/camera-request")}
                >
                  Camera Request
                </a>
              </li>
              <li>
                <a
                  href="/how-to-get-verified"
                  onClick={(e) => handleLinkClick(e, "/how-to-get-verified")}
                >
                  Channel Verification
                </a>
              </li>
              <li>
                <a
                  href="/changelog"
                  onClick={(e) => handleLinkClick(e, "/changelog")}
                >
                  Changelog
                </a>
              </li>

              <li className={styles["dropdown-header"]}>Help and Legal</li>

              <li>
                <a
                  href="/help-support"
                  onClick={(e) => handleLinkClick(e, "/help-support")}
                >
                  Help and Support
                </a>
              </li>
              <li>
                <a
                  href="/privacy-policy"
                  onClick={(e) => handleLinkClick(e, "/privacy-policy")}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/terms-of-service"
                  onClick={(e) => handleLinkClick(e, "/terms-of-service")}
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="/disclaimer"
                  onClick={(e) => handleLinkClick(e, "/disclaimer")}
                >
                  Disclaimer and Copyright
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className={styles["header-middle"]} id="geocoder-container">
      <SearchBar />
      </div>

      <div className={styles["header-right"]}>
      </div>
    </div>
  );
};

export default Header;
