import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SearchBar.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { FaSearch } from "react-icons/fa";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef();

  useEffect(() => {
    if (query.length < 2) {
      setResults([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const timeout = setTimeout(async () => {
      try {
        const res = await fetch("https://server.earf.tv/search-features", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_EARF_API_KEY,
          },
          body: JSON.stringify({ query }),
        });

        const data = await res.json();
        setResults(data.results || []);
      } catch (err) {
        console.error("Search failed:", err);
        setResults([]);
      } finally {
        setLoading(false);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (feature) => {
    navigate(
      `/browse/${encodeURIComponent(
        (feature.properties.tag2 || "unknown")
          .toLowerCase()
          .replace(/\s+/g, "-")
      )}/stream/${feature.properties.videoId}`
    );
    setQuery("");
    setResults([]);
    setIsFocused(false);
  };

  return (
    <div className={styles.searchBar} ref={dropdownRef}>
      <div className={styles.searchWrapper}>
        <FaSearch className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search streams, locations, or categories"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => setIsFocused(true)}
          className={styles.input}
        />
      </div>
      <AnimatePresence>
        {isFocused && (results.length > 0 || query.length > 1 || loading) && (
          <motion.ul
            className={styles.results}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {loading
              ? [...Array(5)].map((_, index) => (
                  <li key={index} className={styles.resultItem}>
                    <div className={styles.skeletonThumbnail}></div>
                    <div className={styles.info}>
                      <div className={styles.skeletonLine}></div>
                      <div className={styles.skeletonSmall}></div>
                      <div className={styles.skeletonChannel}></div>
                    </div>
                  </li>
                ))
              : results.length > 0
              ? results.map((feature) => (
                  <li
                    key={feature.id}
                    className={styles.resultItem}
                    onClick={() => handleSelect(feature)}
                  >
                    <img
                      src={`https://img.youtube.com/vi/${feature.properties.videoId}/default.jpg`}
                      alt={feature.properties.name}
                      className={styles.thumbnail}
                    />
                    <div className={styles.info}>
                      <span
                        style={{
                          color: "#fff",
                          fontWeight: 550,
                          fontSize: "14px",
                          lineHeight: 1.2,
                        }}
                      >
                        {feature.properties.name}
                      </span>

                      <span className={styles.wikidata}>
                        {feature.properties.wikidata || "No Wikidata"}
                      </span>
                      <p>
                        <span
                          style={{
                            color: "var(--earf-blue)",
                            fontWeight: "600",
                            fontSize: "12px",
                            marginRight: "3px", // nice subtle space
                          }}
                        >
                          {feature.properties.tag1}
                        </span>
                        {feature.properties.channelName && (
                          <>
                            <span style={{ margin: "0 6px" }}>•</span>
                            <img
                              src={feature.properties.channelThumbnail}
                              alt={feature.properties.channelName}
                              className={styles.flag}
                            />
                            {feature.properties.channelName}
                            {feature.properties.partner && (
                              <img
                                src="/partner.png"
                                alt="Partner"
                                className={styles.partnerIcon}
                              />
                            )}
                          </>
                        )}
                      </p>
                    </div>
                  </li>
                ))
              : !loading && (
                  <li className={styles.noResults}>
                    Sorry, no results found for "{query}"
                  </li>
                )}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SearchBar;
