import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import Draggable from 'react-draggable';
import { usePlayerContext } from '../context/PlayerContext';
import styles from './AudioPlayer.module.css';

const AudioPlayer = () => {
  const { currentStation, stopStation } = usePlayerContext();
  const audioPlayerRef = useRef(null);
  const stationNameRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const hlsRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [volume, setVolume] = useState(1);
  const fallbackImage = "/Speaker_Icon_grey.png";

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (currentStation && currentStation.isPlaying) {
      playStream(currentStation.url);
    }
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [currentStation]);

  const playStream = (url) => {
    audioPlayerRef.current.src = '';
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }

    if (url.endsWith('.m3u8') || url.includes('.m3u8?')) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hlsRef.current = hls;
        hls.loadSource(url);
        hls.attachMedia(audioPlayerRef.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          audioPlayerRef.current.play().then(() => setIsPlaying(true)).catch(() => setIsPlaying(false));
        });
      } else {
        console.error('HLS not supported');
      }
    } else {
      audioPlayerRef.current.src = url;
      audioPlayerRef.current.play().then(() => setIsPlaying(true)).catch(() => setIsPlaying(false));
    }
  };

  useEffect(() => {
    if (stationNameRef.current) {
      const shouldScroll = stationNameRef.current.scrollWidth > stationNameRef.current.clientWidth;
      setIsScrolling(shouldScroll);
    }
  }, [currentStation]);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioPlayerRef.current.pause();
      setIsPlaying(false);
    } else {
      playStream(currentStation.url);
    }
  };
  const defaultPosition = { x: 0, y: window.innerHeight - 120 }; // Adjust for player height

  const [position, setPosition] = useState(() => {
    const saved = localStorage.getItem('audioPlayerPosition');
    return saved ? JSON.parse(saved) : defaultPosition;
  });
  
  const handleStop = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.pause();
      audioPlayerRef.current.src = '';
    }
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }
    setIsPlaying(false);
    stopStation();
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    audioPlayerRef.current.volume = newVolume;
  };

  const playerContent = (
    <div className={`${styles['audio-player-container']} ${currentStation && currentStation.isPlaying ? styles.show : ''}`}>
      {currentStation && (
        <div className={styles['audio-player-header']}>
          <div className={styles['top-section']} id="drag-handle">
            <img
              src={currentStation.favicon || fallbackImage}
              alt={currentStation.name}
              className={styles['favicon-icon']}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = fallbackImage;
              }}
            />
            <div className={styles['station-info-header']}>
              <span ref={stationNameRef} className={isScrolling ? styles.scroll : ''}>
                {currentStation.name}
              </span>
            </div>
          </div>
          <div className={styles['bottom-section']}>
            <button onClick={handlePlayPause} className={styles['play-pause-button']}>
              <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
            </button>
            <button onClick={handleStop} className={styles['stop-button']}>
              <i className="fas fa-stop"></i>
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              className={styles['volume-slider']}
            />
          </div>
          <audio ref={audioPlayerRef} />
        </div>
      )}
    </div>
  );

  if (!currentStation || !currentStation.isPlaying) return null;

  if (isMobile) return playerContent;
  
  return (
<Draggable
  handle="#drag-handle"
  bounds="body"
  position={position}
  onStop={(_, data) => {
    const newPos = { x: data.x, y: data.y };
    setPosition(newPos);
    localStorage.setItem('audioPlayerPosition', JSON.stringify(newPos));
  }}
>
  <div style={{ position: 'fixed', zIndex: 10000 }}>
    {playerContent}
  </div>
</Draggable>

  );
};

export default AudioPlayer;
