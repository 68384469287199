import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Earthquakes from './Earthquakes';
import Weather from './Weather';
import Wildfires from './Wildfires';
import FBI from './FBI';
import GunViolence from './GunViolence'; // Import the new GunViolence component
import './LiveAlerts.css'; // Custom styling
import { Helmet } from 'react-helmet-async';

const LiveAlerts = ({ handleWildfireLocationSelect }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState('earthquakes');

  useEffect(() => {
    switch (location.pathname) {
      case '/live-alerts/earthquakes':
        setActiveTab('earthquakes');
        document.title = 'Earthquake Alerts | Earf';
        break;
      case '/live-alerts/weather':
        setActiveTab('weather');
        document.title = 'Weather Alerts | Earf';
        break;
      case '/live-alerts/wildfires':
        setActiveTab('wildfires');
        document.title = 'Wildfire Alerts | Earf';
        break;
      case '/live-alerts/FBI':
        setActiveTab('FBI');
        document.title = 'FBI Wanted | Earf';
        break;
      case '/live-alerts/usa-gun-violence':
        setActiveTab('USAViolence');
        document.title = 'USA Gun Violence Alerts | Earf';
        break;
      default:
        setActiveTab('earthquakes');
        document.title = 'Live Alerts | Earf';
        break;
    }
  }, [location.pathname]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case 'earthquakes':
        navigate('/live-alerts/earthquakes');
        break;
      case 'weather':
        navigate('/live-alerts/weather');
        break;
      case 'wildfires':
        navigate('/live-alerts/wildfires');
        break;
      case 'FBI':
        navigate('/live-alerts/FBI');
        break;
      case 'USAViolence':
        navigate('/live-alerts/usa-gun-violence');
        break;
      default:
        navigate('/live-alerts/earthquakes');
    }
  };

  return (
    <div className="live-alerts-container">
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>Live Alerts | Earf</title>
        {/* Meta Description */}
        <meta
          name="description"
          content="Stay informed with Earf's real-time live alerts on weather updates, wildfires, earthquakes, FBI wanted lists, and other crucial events happening around the world."
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/live-alerts" />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Live Alerts | Earf" />
        <meta
          property="og:description"
          content="Get real-time updates on weather events, wildfires, earthquakes, FBI wanted lists, and more with Earf's live alert system. Stay connected to crucial global alerts as they happen."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv/live-alerts" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="929645909242200" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Live Alerts | Earf" />
        <meta
          name="twitter:description"
          content="Stay connected to live alerts on wildfires, weather, earthquakes, FBI wanted lists, and more. Earf brings real-time updates to keep you informed on critical events worldwide."
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf Live Alerts" />
      </Helmet>

      <div className="live-alerts-header">
        <h1>
          Live <span className="earf-blue">Alerts</span>
        </h1>
      </div>

      <div className="live-alerts-tabs">
        <button
          className={activeTab === 'earthquakes' ? 'active-tab' : ''}
          onClick={() => handleTabChange('earthquakes')}
        >
          Earthquakes
        </button>
        <button
          className={activeTab === 'wildfires' ? 'active-tab' : ''}
          onClick={() => handleTabChange('wildfires')}
        >
          Wildfires
        </button>
        <button
          className={activeTab === 'FBI' ? 'active-tab' : ''}
          onClick={() => handleTabChange('FBI')}
        >
          FBI Wanted
        </button>
        <button
          className={activeTab === 'USAViolence' ? 'active-tab' : ''}
          onClick={() => handleTabChange('USAViolence')}
        >
          USA Gun Violence
        </button>
      </div>

      <div className="live-alerts-content">
        <Routes>
          <Route path="/earthquakes" element={<Earthquakes />} />
          <Route path="/weather" element={<Weather />} />
          <Route
            path="/wildfires"
            element={<Wildfires handleWildfireLocationSelect={handleWildfireLocationSelect} />} // Pass the new handler here
          />
          <Route path="/FBI" element={<FBI />} />
          <Route path="/usa-gun-violence" element={<GunViolence />} />
          <Route path="/" element={<Earthquakes />} />
        </Routes>
      </div>
    </div>
  );
};

export default LiveAlerts;
