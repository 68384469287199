import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CountryInfo.module.css";
import { AiOutlineWarning } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";

const imageIcons = {
  officialWebsite: "/Stream-Assets/website.png",
  tripadvisor: "/Stream-Assets/tripadvisor.png",
  wikipedia: "/Stream-Assets/wikipedia.png",
  wikivoyage: "/Stream-Assets/wikivoyage.png",
  twitter: "/Stream-Assets/twitter.png",
  instagram: "/Stream-Assets/instagram.png",
  youtube: "/Stream-Assets/youtube.png",
  google: "/Stream-Assets/google.png",
  map: "/Stream-Assets/map.png",
};

const tooltipMessages = {
  google: (countryName) => `${countryName} on Google`,
  tripadvisor: (countryName) => `${countryName} on TripAdvisor`,
  wikipedia: (countryName) => `${countryName} on Wikipedia`,
  wikivoyage: (countryName) => `${countryName} on Wikivoyage`,
  youtube: (countryName) => `${countryName} on YouTube`,
  twitter: (countryName) => `${countryName} on Twitter`,
  instagram: (countryName) => `${countryName} on Instagram`,
  officialWebsite: (countryName) => `Official website of ${countryName}`,
  map: () => "View on Map",
};

const sortLinksByImportance = (links) => {
  const importanceOrder = [
    "map",
    "google",
    "tripadvisor",
    "wikipedia",
    "wikivoyage",
    "youtube",
    "twitter",
    "instagram",
    "officialWebsite",
  ];
  return links.sort(
    (a, b) => importanceOrder.indexOf(a.type) - importanceOrder.indexOf(b.type)
  );
};

const CountryInfo = ({ countryName, countryCode }) => {
  const [countryInfo, setCountryInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleFeatureClick = (latitude, longitude) => {
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error("Invalid coordinates:", latitude, longitude);
      return;
    }
    navigate(`/explore?lat=${latitude}&lng=${longitude}&zoom=5`);
  };

  useEffect(() => {
    if (!countryCode) {
      setIsLoading(false);
      return;
    }

    const fetchCountryInfo = async () => {
      try {
        const apiKey = process.env.REACT_APP_EARF_API_KEY;
        const response = await fetch(
          `https://server.earf.tv/country-profiles/${countryCode}`,
          {
            headers: { "x-api-key": apiKey },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch country profile");

        const country = await response.json();
        const { properties, geometry } = country;
        const coordinates = geometry?.coordinates || null;

        const links = properties.links
          ? Object.entries(properties.links).map(([key, value]) => ({
              type: key,
              url: value,
            }))
          : [];
        if (coordinates)
          links.push({
            type: "map",
            onClick: () => handleFeatureClick(coordinates[1], coordinates[0]),
          });

        setCountryInfo({
          ...properties,
          coordinates,
          links: sortLinksByImportance(links),
        });
      } catch (error) {
        console.error("Error fetching country info:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountryInfo();
  }, [countryCode]);

  if (isLoading)
    return (
      <div className={styles.infocontainer}>
        <div className={styles.topRow}>
          <div className={styles.headerContent}>
            <div className={styles.skeletonFlagRow}>
              <div className={styles.skeletonFlag}></div>
              <div className={styles.skeletonTitle}></div>
            </div>
            <div className={styles.skeletonMotto}></div>
          </div>
          <div className={styles.skeletonButtons}>
            {Array.from({ length: 7 }).map((_, idx) => (
              <div key={idx} className={styles.skeletonIcon}></div>
            ))}
          </div>
        </div>
        <div className={styles.skeletonDescription}></div>
      </div>
    ); 
    if (!countryInfo)
      return (
        <div className={styles.infocontainer}>
          <div className={styles.skeletonFlagRow}>
            <div className={styles.skeletonFlag}></div>
            <div className={styles.skeletonTitle}></div>
          </div>
        </div>
      );
    
  return (
    <div className={styles.infocontainer}>
      <div className={styles.topRow}>
        <div className={styles.headerContent}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {countryCode && (
              <img
                src={`https://flagcdn.com/w320/${countryCode}.png`}
                alt={`${countryName} flag`}
                className={styles.flag}
              />
            )}
            <h2 style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {countryName}
              <span className={styles.liveText}> Live</span>
              <FaInfoCircle
                className={styles.infoIcon}
                onClick={() =>
                  navigate(`/country/${countryName.toLowerCase()}`)
                }
                style={{ cursor: "pointer" }}
                aria-label="Learn more"
                title="Learn more"
              />
            </h2>
          </div>
          {countryInfo.motto && (
            <p className={styles.motto}>
              <strong>{countryInfo.motto}</strong>
            </p>
          )}
          {countryInfo.alert && (
            <div className={styles.alert}>
              <AiOutlineWarning className={styles.alertIcon} />
              <p>{countryInfo.alert}</p>
            </div>
          )}
        </div>

        {countryInfo.links && (
          <div className={styles.buttons}>
            {countryInfo.links.map((link, index) =>
              link.type === "map" ? (
                <button
                  key={index}
                  onClick={link.onClick}
                  className={styles.iconButton}
                  title={tooltipMessages[link.type]()}
                >
                  <img src={imageIcons[link.type]} alt={link.type} />
                </button>
              ) : (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.iconButton}
                  title={tooltipMessages[link.type](countryName)}
                >
                  <img src={imageIcons[link.type]} alt={link.type} />
                </a>
              )
            )}
          </div>
        )}
      </div>

      {countryInfo.description && (
        <div className={styles.description}>
          <p>{countryInfo.description}</p>
        </div>
      )}
    </div>
  );
};

export default CountryInfo;
