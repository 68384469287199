import React, { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate, Link, useLocation } from "react-router-dom"; // Import Link for internal navigation
import "./CameraRequest.css";
import { Helmet } from "react-helmet-async";

const CameraRequest = () => {
  const form = useRef();
  const [streetAddress, setStreetAddress] = useState("");
  const navigate = useNavigate(); // For back button navigation
  const location = useLocation();

  useEffect(() => {
    emailjs.init("r6SlRwhc0oMyvh312"); // Replace with your actual EmailJS public key

    const loadScript = (url, callback) => {
      const existingScript = document.getElementById("googleMaps");
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = url;
        script.id = "googleMaps";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        script.onload = callback; // Ensures the callback is invoked once script is loaded
      } else {
        if (callback) callback();
      }
    };

    const initAutocomplete = () => {
      const input = document.getElementById("street_address");
      if (input && window.google) {
        const autocomplete = new window.google.maps.places.Autocomplete(input);
        autocomplete.setOptions({ fields: ["formatted_address"] });
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address) {
            setStreetAddress(place.formatted_address);
          }
        });
      }
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
      () => {
        if (window.google) {
          initAutocomplete();
        }
      }
    );
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_31xlehj", // Replace with your Service ID
        "template_a1zpq89", // Replace with your Camera Request Template ID
        form.current
      )
      .then(
        (result) => {
          alert("Camera request submitted successfully!");
        },
        (error) => {
          alert("Failed to submit the camera request, please try again.");
        }
      );
    e.target.reset(); // Reset form after submission
  };

  return (
    <div className="content-wrapper">
      <div className="camera-request-container">
        <Helmet prioritizeSeoTags>
          <title>Camera Request | Earf</title>
          <meta
            name="description"
            content="Submit your camera or location to be featured on Earf's live-streaming platform, connecting viewers with unique places around the world."
          />
          <link rel="canonical" href="https://earf.tv/camera-request" />
          <meta property="og:title" content="Camera Request | Earf" />
          <meta
            property="og:description"
            content="Have a camera or unique location to share? Submit it to Earf, the live-streaming platform showcasing Earth’s beauty, and let the world see through your lens."
          />
          <meta property="og:image" content="https://earf.tv/Earf-E.png" />
          <meta property="og:url" content="https://earf.tv/camera-request" />
          <meta property="og:type" content="website" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Camera Request | Earf" />
          <meta property="fb:app_id" content="929645909242200" />

          <meta
            name="twitter:description"
            content="Submit your camera or location to be featured on Earf's live-streaming platform, showcasing incredible views from around the world."
          />
          <meta name="twitter:image" content="https://earf.tv/Earf-E.png" />
          <meta name="twitter:image:alt" content="Earf Logo" />
        </Helmet>

        <div className="header-container">
        <h1 className="camera-request-header">
  <img
    src="/Earf-E.png"
    alt="Earf Logo"
    className="contact-logo"
    style={{ marginRight: "15px" }}
  />
  Request{" "}
  <span style={{ color: "rgba(104, 223, 223, 0.733)" }}>
    Location
  </span>
</h1>

        </div>

        <form ref={form} onSubmit={sendEmail} className="camera-request-form">
          {/* Personal or Business Information */}
          <div className="form-group">
            <label htmlFor="name">Name (Personal or Business)</label>
            <input
              type="text"
              id="name"
              name="user_name"
              required
              placeholder="Your Name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email (Personal or Business)</label>
            <input
              type="email"
              id="email"
              name="user_email"
              required
              placeholder="Your Email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="website">Website (Personal or Business)</label>
            <input
              type="url"
              id="website"
              name="user_website"
              placeholder="Your Website"
            />
          </div>

          {/* Camera Details */}
          <div className="form-group">
            <label htmlFor="camera_name">Camera Name</label>
            <input
              type="text"
              id="camera_name"
              name="camera_name"
              required
              placeholder="Camera Name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="stream_url">
              Stream URL to Camera (with videoId)
            </label>
            <input
              type="url"
              id="stream_url"
              name="stream_url"
              required
              placeholder="Stream URL"
            />
          </div>

          <div className="form-group">
            <label htmlFor="channel_name">
              Channel Name (If YouTube, Optional)
            </label>
            <input
              type="text"
              id="channel_name"
              name="channel_name"
              placeholder="Channel Name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="camera_location">
              Camera Location (Latitude, Longitude)
            </label>
            <input
              type="text"
              id="camera_location"
              name="camera_location"
              required
              placeholder="Latitude, Longitude"
            />
          </div>

          <div className="form-group">
            <label htmlFor="camera_address">
              Camera Address (e.g., London, United Kingdom)
            </label>
            <input
              type="text"
              id="camera_address"
              name="camera_address"
              required
              placeholder="Camera Address"
            />
          </div>

          {/* Autocomplete Street Address using Google Places */}
          <div className="form-group">
            <label htmlFor="street_address">
              Street Name and Number Where Camera is Located
            </label>
            <input
              type="text"
              id="street_address"
              name="autocomplete_street_address"
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
              required
              placeholder="Start typing the street address"
            />
          </div>

          {/* Hidden field to hold street_address value */}
          <input type="hidden" name="street_name" value={streetAddress} />

          {/* Full width fields */}
          <div className="form-group full-width">
            <label htmlFor="camera_description">Camera Description</label>
            <textarea
              id="camera_description"
              name="camera_description"
              rows="5"
              required
              placeholder="Describe the camera, what it looks at, and its main function."
            ></textarea>
          </div>

          <div className="form-group full-width">
            <label htmlFor="camera_offline_frequency">
              How often do you expect this camera to go offline?
            </label>
            <select
              id="camera_offline_frequency"
              name="camera_offline_frequency"
              required
            >
              <option value="" disabled selected>
                -- Select Frequency --
              </option>
              <option value="Hourly">Hourly</option>
              <option value="Daily">Daily</option>
              <option value="Monthly">Monthly</option>
              <option value="Yearly">Yearly</option>
              <option value="Never">Never</option>
            </select>
          </div>
          {/* Verification Request Info Block */}
          <div className="verification-info">
            <p>
              We may contact you further for verification details. If you'd like
              to proceed with verification and receive a channel{" "}
              <img
                src="/partner.png"
                alt="Partner Icon"
                className="partner-icon"
              />{" "}
              immediately, please visit our{" "}
              <Link to="/how-to-get-verified" className="verify-email">
                Verification Page
              </Link>{" "}
              to send your request.
            </p>
          </div>

          {/* Submit button */}
          <div className="form-group full-width">
            <div className="form-button">
              <button type="submit">Submit Camera Request</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CameraRequest;
