import React, { memo, useState, useEffect, useRef } from "react";
import * as turf from "@turf/turf";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify"; // Make sure toast is imported from react-toastify
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "./Mapbox.css";

const Mapbox = ({
  isCollapsed,
  selectedLocation,
  onFeatureClick,
  mapState,
  setMapRef,
}) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const rainViewerCleanupRef = useRef(null);
  const geocoderRef = useRef(null); // Geocoder reference
  const location = useLocation(); // Get the location object from react-router-dom
  const navigate = useNavigate(); // Use navigate for routing
  const [cartelGeoJSON, setCartelGeoJSON] = useState(null);
  const [cartelInfo, setCartelInfo] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const lat = parseFloat(queryParams.get("lat"));
  const lng = parseFloat(queryParams.get("lng"));
  const zoom = parseFloat(queryParams.get("zoom"));

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    if (!mapboxgl.accessToken) {
      console.error(
        "Mapbox access token is not defined in environment variables."
      );
      return;
    }

    // Create the map instance only if it does not exist yet.
    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        center: mapState ? mapState.center : [-98.5795, 39.8283],
        zoom: mapState ? mapState.zoom : 2,
        attributionControl: false,
      });

      mapRef.current.on("load", () => {
        // Existing initializations
        initializeRainViewer(mapRef.current);
        addTerrainExaggeration(mapRef.current);
        initializeTrafficLayer(mapRef.current);
        initializeLiveCamerasLayer(mapRef.current, onFeatureClick);
        initializeEarthquakeLayer(mapRef.current);
        setMapRef(mapRef.current);
        initializeISSLayer(mapRef.current);
        addGunViolenceLayer(mapRef.current);
        // Add GeolocateControl with all true options
        const geolocateControl = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
          showUserLocation: true,
          fitBoundsOptions: {
            maxZoom: 15,
            linear: true,
          },
        });

        mapRef.current.addControl(geolocateControl, "top-right");

        geolocateControl.on("error", (error) => {
          toast.error("Error retrieving location.");
          console.error("Geolocate error:", error);
        });
      });
      // If lat and lng are present in the query parameters, fly to those coordinates
      if (lat && lng) {
        mapRef.current.flyTo({
          center: [lng, lat],
          zoom: zoom ? zoom : 20, // Use provided zoom or default to 20
          essential: true,
        });
      }

      // Set light preset based on time of day when the style is loaded
      mapRef.current.on("style.load", () => {
        const lightPreset = getLightPreset();
        mapRef.current.setConfigProperty("basemap", "lightPreset", lightPreset);
      });
      if (!geocoderRef.current) {
        geocoderRef.current = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          placeholder: "Search",
        });

        const geocoderContainer = document.getElementById("geocoder-container");
        if (geocoderContainer && !geocoderContainer.hasChildNodes()) {
          geocoderContainer.appendChild(
            geocoderRef.current.onAdd(mapRef.current)
          );
        }
      }
    }

    // Run cleanup only when component unmounts.
    return () => {
      if (rainViewerCleanupRef.current) {
        rainViewerCleanupRef.current();
      }
      if (geocoderRef.current) {
        const geocoderElement = document.querySelector(
          ".mapboxgl-ctrl-geocoder"
        );
        if (geocoderElement && geocoderElement.parentNode) {
          geocoderElement.parentNode.removeChild(geocoderElement);
        }
        geocoderRef.current = null;
      }
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []); // Empty dependency array means the map is only initialized once.

  // Handle sidebar collapse/expand
  useEffect(() => {
    if (mapRef.current) {
      // Add a small delay before resizing the map to ensure DOM changes are fully applied
      setTimeout(() => {
        mapRef.current.resize();
      }, 300); // Adjust the delay (in ms) if necessary
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (mapRef.current && selectedLocation) {
      mapRef.current.flyTo({
        center: [selectedLocation.lng, selectedLocation.lat],
        zoom: selectedLocation.zoom,
        essential: true,
      });
    }
  }, [selectedLocation]);

  // Function to get the time of day and map it to a light preset
  function getLightPreset() {
    const LPcurrentTime = new Date().getHours();

    if (LPcurrentTime >= 4 && LPcurrentTime < 6) {
      return "dusk";
    } else if (LPcurrentTime >= 6 && LPcurrentTime < 16) {
      return "day";
    } else if (LPcurrentTime >= 16 && LPcurrentTime < 21) {
      return "dusk";
    } else {
      return "night";
    }
  }

  //Cartel and Gang Territories
  useEffect(() => {
    fetch("/mexican_cartels.geojson", {
      headers: { Accept: "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        setCartelGeoJSON(data);
      })
      .catch((error) =>
        console.error("❌ Error loading cartel GeoJSON:", error)
      );
  }, []);

  useEffect(() => {
    if (!mapRef.current || !cartelGeoJSON) {
      return;
    }

    const updateCartelPresence = () => {
      if (
        !mapRef.current ||
        !mapRef.current.getCenter ||
        !mapRef.current.getZoom
      )
        return;

      const zoomLevel = mapRef.current.getZoom();
      if (zoomLevel < 5) {
        setCartelInfo(null);
        removeCartelHighlight(); // Remove highlight if zoom is below 7
        return;
      }

      const { lng, lat } = mapRef.current.getCenter();
      const userPoint = turf.point([lng, lat]);

      let foundCartel = null;
      let cartelPolygon = null;

      cartelGeoJSON.features.forEach((feature) => {
        if (turf.booleanPointInPolygon(userPoint, feature.geometry)) {
          if (feature.properties.cartel !== "Unknown") {
            // Hide if "Unknown"
            foundCartel = {
              cartel: feature.properties.cartel,
              state: feature.properties.state,
              qid: feature.properties.qid,
            };
            cartelPolygon = feature.geometry; // Get the polygon for highlighting
          }
        }
      });

      setCartelInfo(foundCartel);

      if (foundCartel) {
        highlightCartelZone(cartelPolygon); // Highlight the zone
      } else {
        removeCartelHighlight(); // Remove highlight if leaving
      }
    };

    if (!mapRef.current.loaded()) {
      mapRef.current.once("load", () => {
        mapRef.current.on("move", updateCartelPresence);
        updateCartelPresence();
      });
    } else {
      mapRef.current.on("move", updateCartelPresence);
      updateCartelPresence();
    }

    return () => {
      if (mapRef.current) mapRef.current.off("move", updateCartelPresence);
    };
  }, [mapRef.current, cartelGeoJSON]);

  const highlightCartelZone = (geometry) => {
    if (!mapRef.current) return;

    if (mapRef.current.getSource("cartel-zone")) {
      mapRef.current.getSource("cartel-zone").setData({
        type: "FeatureCollection",
        features: [{ type: "Feature", geometry, properties: {} }],
      });
    } else {
      mapRef.current.addSource("cartel-zone", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [{ type: "Feature", geometry, properties: {} }],
        },
      });

      mapRef.current.addLayer({
        id: "cartel-zone-highlight",
        type: "fill",
        source: "cartel-zone",
        paint: {
          "fill-color": "#ff0000", // Red highlight
          "fill-opacity": 0.3,
          "fill-outline-color": "#8b0000",
          "fill-emissive-strength": 1,
        },
      });
    }
  };
  const removeCartelHighlight = () => {
    if (mapRef.current && mapRef.current.getSource("cartel-zone")) {
      mapRef.current.getSource("cartel-zone").setData({
        type: "FeatureCollection",
        features: [],
      });
    }
  };

  // Function to add terrain exaggeration
  const addTerrainExaggeration = (map) => {
    map.addSource("mapbox-terrain", {
      type: "vector",
      url: "mapbox://mapbox.mapbox-terrain-v1",
      tileSize: 512,
      minzoom: 2,
    });

    // add the DEM source as a terrain layer with exaggerated height
    map.setTerrain({ source: "mapbox-terrain", exaggeration: 2.5 });
  };

  const initializeRainViewer = (map) => {
    let apiData = null;
    let i = 0;
    let totalFrames = 0;
    let layerIds = [];
    let animationFrameRef = null;
    let dataRefreshInterval;
    let isMapActive = true;

    // Fetch radar data without toastify
    const fetchAndRefreshMapData = async () => {
      if (!isMapActive) return;

      try {
        console.log("Fetching weather radar data...");
        const res = await fetch(
          "https://api.rainviewer.com/public/weather-maps.json"
        );

        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }

        apiData = await res.json();

        // Remove existing layers
        layerIds.forEach((layerId) => {
          if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
          }
          if (map.getSource(layerId)) {
            map.removeSource(layerId);
          }
        });

        layerIds = [];

        if (apiData?.radar?.nowcast) {
          apiData.radar.nowcast.forEach((frame) => {
            const layerId = `rainviewer_${frame.path}`;

            if (!map.getSource(layerId)) {
              map.addSource(layerId, {
                type: "raster",
                tiles: [
                  `${apiData.host}${frame.path}/512/{z}/{x}/{y}/2/1_1.png`,
                ],
                tileSize: 512,
              });

              map.addLayer({
                id: layerId,
                type: "raster",
                source: layerId,
                layout: { visibility: "visible" },
                paint: {
                  "raster-opacity": 0,
                  "raster-emissive-strength": 1,
                },
              });

              layerIds.push(layerId);
            }
          });

          totalFrames = apiData.radar.nowcast.length;

          if (animationFrameRef) {
            cancelAnimationFrame(animationFrameRef);
          }
          if (totalFrames > 0) {
            animateFrames();
          }

          console.log("✅ Weather radar data updated successfully.");
        } else {
          console.warn("⚠️ No radar data available.");
        }
      } catch (error) {
        console.error("❌ Error fetching radar data:", error);
      }
    };

    const animateFrames = () => {
      if (!isMapActive || !apiData?.radar?.nowcast) {
        return;
      }

      const fadeInDuration = 300; // Number of frames for fade-in effect
      let fadeInCounter = 0;

      const animate = () => {
        if (!isMapActive) return;

        const currentLayerId = layerIds[i];
        const nextLayerIndex = (i + 1) % totalFrames;
        const nextLayerId = layerIds[nextLayerIndex];

        if (map.getLayer(currentLayerId)) {
          map.setPaintProperty(
            currentLayerId,
            "raster-opacity",
            0.6 - (fadeInCounter / fadeInDuration) * 0.6
          );
        }

        if (map.getLayer(nextLayerId)) {
          map.setPaintProperty(
            nextLayerId,
            "raster-opacity",
            (fadeInCounter / fadeInDuration) * 0.6
          );
        }

        fadeInCounter++;

        if (fadeInCounter >= fadeInDuration) {
          fadeInCounter = 0;
          i = nextLayerIndex;
        }

        animationFrameRef = requestAnimationFrame(animate);
      };

      animationFrameRef = requestAnimationFrame(animate);
    };

    const setupDataRefreshInterval = () => {
      if (dataRefreshInterval) {
        clearInterval(dataRefreshInterval);
      }
      dataRefreshInterval = setInterval(() => {
        if (isMapActive) {
          fetchAndRefreshMapData()
            .then(() => console.log("✅ Weather data refreshed."))
            .catch((error) =>
              console.error("❌ Failed to refresh weather data:", error)
            );
        }
      }, 300000); // Refresh data every 5 minutes
    };

    const initialize = async () => {
      try {
        await fetchAndRefreshMapData();
        setupDataRefreshInterval();
      } catch (error) {
        console.error("❌ Failed to initialize weather data:", error);
      }
    };

    if (map.loaded()) {
      initialize();
    } else {
      map.on("load", initialize);
    }

    // Clean up function for when the component unmounts
    rainViewerCleanupRef.current = () => {
      isMapActive = false;

      if (animationFrameRef) {
        cancelAnimationFrame(animationFrameRef);
      }
      if (dataRefreshInterval) {
        clearInterval(dataRefreshInterval);
      }

      layerIds.forEach((layerId) => {
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
        if (map.getSource(layerId)) {
          map.removeSource(layerId);
        }
      });

      layerIds = [];
    };
  };

  // Function to initialize traffic layer
  const initializeTrafficLayer = (map) => {
    // Add traffic source from Mapbox
    map.addSource("traffic-data", {
      type: "vector",
      url: "mapbox://mapbox.mapbox-traffic-v1",
    });

    // Define colors for different levels of congestion
    const colors = {
      low: "green",
      moderate: "yellow",
      heavy: "orange",
      severe: "red",
      closed: "black",
    };

    // Add layers for different congestion levels
    for (const severity in colors) {
      if (severity !== "closed") {
        map.addLayer({
          id: "traffic-" + severity,
          type: "line",
          source: "traffic-data",
          "source-layer": "traffic",
          filter: [
            "all",
            ["==", "congestion", severity],
            ["!=", "closed", "yes"],
          ],
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": colors[severity],
            "line-opacity": 0.5, // Set opacity
            "line-width": ["interpolate", ["linear"], ["zoom"], 10, 1, 22, 7],
            "line-emissive-strength": 1,
            "line-offset": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              0,
              15,
              1,
              22,
              10,
            ],
          },
          minzoom: 10,
        });
      }
    }

    // Add layer for closed roads
    map.addLayer({
      id: "traffic-closed",
      type: "line",
      source: "traffic-data",
      "source-layer": "traffic",
      filter: ["==", "closed", "yes"],
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": colors["closed"],
        "line-opacity": 0.5,
        "line-width": ["interpolate", ["linear"], ["zoom"], 10, 1, 22, 7],
        "line-emissive-strength": 1,
        "line-offset": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          0,
          15,
          1,
          22,
          10,
        ],
      },
      minzoom: 10,
    });
  };

  const initializeLiveCamerasLayer = (map, onFeatureClick) => {
    // Load GeoJSON data for live cameras
    fetch("https://server.earf.tv/live-cameras", {
      method: "POST",
      headers: {
        "x-api-key": process.env.REACT_APP_EARF_API_KEY,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Filter the features to include only those with status 'online'
        const onlineFeatures = (data.features || []).filter(
          (feature) => feature.properties.status === "online"
        );

        const filteredData = {
          ...data,
          features: onlineFeatures, // Replace features with filtered ones
        };

        // Add the GeoJSON source with clustering enabled
        map.addSource("LiveCameras", {
          type: "geojson",
          data: filteredData,
          cluster: true,
          clusterMaxZoom: 12, // Max zoom to cluster points on
          clusterRadius: 35, // Radius of each cluster when clustering points (default is 50)
        });

        // Load and add icon images for different camera types
        const camicons = {
          cluster: "Location-Star.png",
          place: "place-icon.png",
          airport: "airport-icon.png",
          rail: "rail-icon.png",
          animal: "animal-icon.png",
          beach: "beach-icon.png",
          news: "news-icon.png",
          walking: "walkingtour-icon.png",
          driving: "drivingtour-icon.png",
          drone: "dronetour-icon.png",
        };

        // Use promises to load all icons
        const iconLoadPromises = Object.entries(camicons).map(
          ([type, icon]) => {
            return new Promise((resolve, reject) => {
              map.loadImage(`/Location-Assets/${icon}`, (error, image) => {
                if (error) {
                  reject(`Error loading icon: ${icon}`);
                } else {
                  map.addImage(type, image);
                  resolve();
                }
              });
            });
          }
        );

        // Optionally add a default icon if necessary
        iconLoadPromises.push(
          new Promise((resolve, reject) => {
            map.loadImage(
              "/Location-Assets/default-icon.png",
              (error, image) => {
                if (error) {
                  reject("Error loading default icon");
                } else {
                  map.addImage("default-icon", image);
                  resolve();
                }
              }
            );
          })
        );

        // Wait for all icons to be loaded before adding the layers
        Promise.all(iconLoadPromises)
          .then(() => {
            // Add the clustered points layer
            map.addLayer({
              id: "clusters",
              type: "symbol",
              source: "LiveCameras",
              filter: ["has", "point_count"],
              minzoom: 10,
              layout: {
                "icon-image": "cluster",
                "icon-size": [
                  "interpolate",
                  ["exponential", 1],
                  ["zoom"],
                  10,
                  0.4,
                  21,
                  0.8,
                ],
                "text-field": "{point_count_abbreviated}",
                "text-font": ["Arial Unicode MS Bold"],
                "text-size": [
                  "interpolate",
                  ["exponential", 1],
                  ["zoom"],
                  10,
                  18,
                  21,
                  25,
                ],
                "text-offset": [0, 0],
                "text-anchor": "center",
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "symbol-sort-key": 9998,
              },
              paint: {
                "icon-opacity": 0.7,
                "text-color": "rgb(255, 193, 7)",
                "text-halo-color": "black",
                "text-halo-width": 2,
              },
            });

            // Add click event for clusters to zoom in and expand
            map.on("click", "clusters", (e) => {
              const features = map.queryRenderedFeatures(e.point, {
                layers: ["clusters"],
              });
              const clusterId = features[0].properties.cluster_id;
              map
                .getSource("LiveCameras")
                .getClusterExpansionZoom(clusterId, (err, zoom) => {
                  if (err) {
                    console.error("Error expanding cluster:", err);
                    return;
                  }

                  map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom,
                  });
                });
            });

            // Change cursor to pointer when hovering over clusters
            map.on("mouseenter", "clusters", () => {
              map.getCanvas().style.cursor = "pointer";
            });
            map.on("mouseleave", "clusters", () => {
              map.getCanvas().style.cursor = "";
            });

            // Add the unclustered points layer
            map.addLayer({
              id: "unclustered-points",
              type: "symbol",
              source: "LiveCameras",
              filter: ["!", ["has", "point_count"]],
              minzoom: 10,
              layout: {
                "icon-image": [
                  "match",
                  ["get", "type"],
                  "place",
                  "place",
                  "rail",
                  "rail",
                  "animal",
                  "animal",
                  "beach",
                  "beach",
                  "news",
                  "news",
                  "walking",
                  "walking",
                  "driving",
                  "driving",
                  "drone",
                  "drone",

                  /* default */ "default-icon",
                ],
                "icon-size": [
                  "interpolate",
                  ["exponential", 1],
                  ["zoom"],
                  10,
                  0.3,
                  21,
                  0.7,
                ],
                "icon-allow-overlap": true,
                "symbol-sort-key": 9998,
              },
              paint: {
                "icon-opacity": 0.7,
              },
            });

            // Add click event for unclustered points
            map.on("click", "unclustered-points", (e) => {
              const feature = e.features[0];
              if (onFeatureClick) {
                onFeatureClick(feature); // Use the feature that was clicked to show VideoView
              }
            });

            // Change cursor to pointer when hovering over unclustered points
            map.on("mouseenter", "unclustered-points", () => {
              map.getCanvas().style.cursor = "pointer";
            });
            map.on("mouseleave", "unclustered-points", () => {
              map.getCanvas().style.cursor = "";
            });
          })
          .catch((error) => {
            console.error("Error loading icons:", error);
          });
      })
      .catch((error) => {
        console.error("Error loading GeoJSON data:", error);
      });
    // Add hover popup for unclustered points
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "dark-popup",
    });

    map.on("mouseenter", "unclustered-points", (e) => {
      const feature = e.features[0];
      const {
        name,
        thumbnail,
        wikidata,
        tag1,
        tag2,
        tag3,
        channelName,
        channelThumbnail,
      } = feature.properties;

      // Prepare tags as styled badges
      const tags = [tag1, tag2, tag3]
        .filter(Boolean)
        .map((tag) => `<span class="popup-tag">${tag.trim()}</span>`)
        .join(" ");

      // Set popup content
      popup
        .setLngLat(feature.geometry.coordinates)
        .setHTML(
          `
          <div class="popup-content">
            <div class="popup-thumbnail">
              <img src="${thumbnail}" alt="${name}" />
            </div>
            <div class="popup-info">
              <h4>${name || "Unknown"}</h4>
              <p class="wikidata">${wikidata || "N/A"}</p>
              
              <!-- Channel thumbnail and name -->
              <div class="popup-channel">
                <div class="popup-channel-thumbnail">
                  <img src="${
                    channelThumbnail || "/placeholder-channel.jpg"
                  }" alt="${channelName || "Unknown Channel"}" />
                </div>
                <span class="popup-channel-name">${
                  channelName || "Unknown Channel"
                }</span>
              </div>
    
              <div class="popup-tags">${tags || "<span>No tags</span>"}</div>
            </div>
          </div>
        `
        )
        .addTo(map);

      map.getCanvas().style.cursor = "pointer";
    });

    // Remove popup on mouse leave
    map.on("mouseleave", "unclustered-points", () => {
      popup.remove();
      map.getCanvas().style.cursor = "";
    });
  };

  // Initialize the earthquake layer on the map
  const initializeEarthquakeLayer = async (map) => {
    const response = await fetch(
      "https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/significant_week.geojson"
    );
    const data = await response.json();

    const size = 150;
    const pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),
      onAdd: function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d");
      },
      render: function () {
        const duration = 1000;
        const t = (performance.now() % duration) / duration;
        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;

        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = `rgba(255, 0, 0, 0.3)`;
        context.fill();

        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = "rgba(255, 100, 100, 0.51)";
        context.strokeStyle = "red";
        context.lineWidth = 2 + 2 * (1 - t);
        context.fill();
        context.stroke();

        this.data = context.getImageData(0, 0, this.width, this.height).data;
        map.triggerRepaint();
        return true;
      },
    };

    if (!map.hasImage("pulsing-dot")) {
      map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });
    }

    map.addSource("earthquakes", {
      type: "geojson",
      data: data,
    });

    map.addLayer({
      id: "earthquake-pulsing-dots",
      type: "symbol",
      source: "earthquakes",
      layout: {
        "icon-image": "pulsing-dot",
        "icon-size": ["interpolate", ["linear"], ["zoom"], 0, 0.5, 12, 1.5],
      },
    });

    // Add click event for 'earthquake-pulsing-dots'
    map.on("click", "earthquake-pulsing-dots", (e) => {
      const earthquakeURL = e.features[0].properties.url; // The URL for the USGS page
      if (earthquakeURL) {
        window.open(earthquakeURL, "_blank"); // Open USGS page in a new tab
      }
    });
    // Change the cursor to pointer when hovering over 'earthquake-pulsing-dots'
    map.on("mouseenter", "earthquake-pulsing-dots", () => {
      map.getCanvas().style.cursor = "pointer"; // Set the cursor to pointer
    });

    // Reset the cursor when leaving the 'earthquake-pulsing-dots' layer
    map.on("mouseleave", "earthquake-pulsing-dots", () => {
      map.getCanvas().style.cursor = ""; // Reset the cursor to default
    });
  };

  const initializeISSLayer = (map, apiKey) => {
    // Load ISS icon
    map.loadImage("/Location-Assets/iss-icon.png", (error, image) => {
      if (error) {
        console.error("Error loading ISS icon:", error);
        return;
      }
      map.addImage("iss-icon", image);

      // Add GeoJSON source for the ISS location
      map.addSource("ISSLocation", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      // Add the ISS layer with dynamic icon size
      map.addLayer({
        id: "iss-layer",
        type: "symbol",
        source: "ISSLocation",
        layout: {
          "icon-image": "iss-icon",
          "icon-size": [
            "interpolate",
            ["exponential", 1.5], // Smooth scaling
            ["zoom"],
            0,
            0.05, // Smallest size at zoom 0
            12,
            0.1, // Moderate size at zoom 12
            22,
            0.5, // Largest size at zoom 22
          ],
          "icon-allow-overlap": true,
        },
      });

      // Function to fetch ISS location and update the GeoJSON source
      const fetchISSLocation = async () => {
        try {
          const response = await fetch(`https://server.earf.tv/iss-location`, {
            method: "GET",
            headers: {
              "x-api-key": process.env.REACT_APP_EARF_API_KEY,
              "Content-Type": "application/json",
              "X-Request-Source": "SpaceStation",
            },
          });

          if (!response.ok) {
            throw new Error(
              `Failed to fetch ISS location: ${response.statusText}`
            );
          }

          const data = await response.json();

          if (data.success && data.location) {
            const { latitude, longitude } = data.location;

            // Update the GeoJSON source with the new ISS location
            const issFeature = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [longitude, latitude],
              },
              properties: {
                title: "International Space Station",
              },
            };

            map.getSource("ISSLocation").setData({
              type: "FeatureCollection",
              features: [issFeature],
            });
          } else {
            console.error("Invalid data received:", data);
          }
        } catch (error) {
          console.error("Error fetching ISS location:", error.message);
        }
      };

      // Fetch ISS location every 2 seconds
      fetchISSLocation(); // Initial fetch
      setInterval(fetchISSLocation, 2000);

      // Add click event to navigate to the specified URL using React's navigate
      map.on("click", "iss-layer", () => {
        navigate("/browse/Space"); // Use react-router-dom navigate for React routing
      });
    });
  };

  // Function to resize an image using a canvas
  const resizeImage = (url, width, height, callback) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      callback(canvas.toDataURL());
    };
  };

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()]; // Get month name from the months array
    const year = date.getFullYear();

    return `${day} ${month} ${year}`; // Example: "20 November 2024"
  };

  const addGunViolenceLayer = (map) => {
    const originalIconUrl = "crime.png"; // Path to the original icon

    // Resize the icon before loading it
    resizeImage(originalIconUrl, 32, 32, (resizedIconUrl) => {
      map.loadImage(resizedIconUrl, (error, image) => {
        if (error) {
          console.error("Error loading resized gun icon:", error);
          return;
        }

        // Add the resized image to the map
        map.addImage("gun-icon", image);

        // Fetch the gun violence data (with headers for API key)
        const apiKey = process.env.REACT_APP_EARF_API_KEY;

        fetch("https://server.earf.tv/gun-violence/last-72-hours", {
          headers: {
            "x-api-key": apiKey, // Add the API key to the request headers
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // Transform the gun violence data into GeoJSON format
            const gunViolenceGeoJSON = {
              type: "FeatureCollection",
              features: data.map((incident) => ({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [incident.longitude, incident.latitude], // Using the coordinates from the data
                },
                properties: {
                  title: `Incident: ${incident.address}`,
                  description: `${incident.city}, ${incident.state}`,
                  date: incident.date, // Incident date
                  killed: incident.killed, // Number of people killed
                  injured: incident.injured, // Number of people injured
                  viewSource: incident.operations.viewSource, // View source URL
                  viewIncident: incident.operations.viewIncident, // View incident URL
                },
              })),
            };

            // Add a GeoJSON source for the gun icons
            map.addSource("gun-violence", {
              type: "geojson",
              data: gunViolenceGeoJSON,
            });

            // Add a layer to display the gun icons
            map.addLayer({
              id: "guns-layer",
              type: "symbol",
              source: "gun-violence",
              layout: {
                "icon-image": "gun-icon", // The resized gun icon
                "icon-size": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  10,
                  0.3,
                  15,
                  1,
                ], // Dynamic size scaling
                "icon-allow-overlap": true, // Allow icons to overlap
              },
              minzoom: 10, // Minimum zoom level for displaying icons
            });

            // Add click functionality to open the URL in a new tab
            map.on("click", "guns-layer", (e) => {
              const properties = e.features[0].properties;

              // Determine the URL to open
              const url = properties.viewSource || properties.viewIncident;

              // Open the URL if it exists
              if (url) {
                window.open(url, "_blank", "noopener,noreferrer");
              } else {
                console.warn("No URL found for this incident.");
              }
            });

            // Create a popup for hover interaction, using a custom CSS class for dark theme styling
            const popup = new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
              className: "gun-violence-popup", // This class will be used to style the popup
            });

            // Add mouse events for hover functionality
            map.on("mouseenter", "guns-layer", (e) => {
              // Change the cursor to pointer
              map.getCanvas().style.cursor = "pointer";

              // Get the feature properties
              const properties = e.features[0].properties;

              // Use the formatted date
              const formattedDate = formatDate(properties.date);

              // Set the popup content
              popup
                .setLngLat(e.lngLat)
                .setHTML(
                  `<h3 style="
                  margin: 0;
                  padding-bottom: 4px;
                  border-bottom: 1px solid grey;
                  width: 95%;
                  display: inline-block;
                ">
                  <span style="color: var(--earf-blue)">EARF</span> Shot Spotter
                </h3>
                <strong>${properties.title}</strong><br>
                <em>${properties.description}</em><br>
                <strong>${formattedDate}<br></strong>
                <strong>Killed:</strong> ${properties.killed}<br>
                <strong>Injured:</strong> ${properties.injured}<br>`
                )
                .addTo(map);
            });

            map.on("mouseleave", "guns-layer", () => {
              // Reset the cursor and remove the popup
              map.getCanvas().style.cursor = "";
              popup.remove();
            });
          })
          .catch((error) => {
            console.error("Error fetching gun violence data:", error);
          });
      });
    });
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>Explore | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Explore Earth's beauty with Earf's interactive map. View live cameras, real-time weather updates, earthquake data, traffic information, and more as you navigate across the globe."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/explore" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Explore | Earf" />
        <meta
          property="og:description"
          content="Explore Earf's interactive map with live cameras, real-time weather, earthquake data, and traffic information. Navigate the globe and experience Earth's beauty in real-time."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv/explore" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="929645909242200" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Explore | Earf" />
        <meta
          name="twitter:description"
          content="Discover the world with Earf's interactive map, featuring live cameras, real-time weather, earthquake data, and traffic info."
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf Explore Map" />
      </Helmet>

      <div className="map-container" ref={mapContainerRef} />
      {cartelInfo && (
        <div className="cartel-overlay">
          <h3 className="cartel-state">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg"
              alt="Mexico Flag"
            />
            {cartelInfo.state}
          </h3>

          {![
            "Disputed Territory",
            "Low Cartel Activity",
            "Moderate Cartel Activity",
          ].includes(cartelInfo.cartel) && (
            <p className="cartel-controlled">Under Cartel Control</p>
          )}

          <p
            className="cartel-name"
            style={{ display: "flex", alignItems: "center" }}
          >
            {![
              "Disputed Territory",
              "Low Cartel Activity",
              "Moderate Cartel Activity",
            ].includes(cartelInfo.cartel) && (
              <img
                src={`/cartels/${cartelInfo.cartel.split(" ").join("-")}.png`}
                alt={cartelInfo.cartel}
                style={{ width: "50px", height: "30px", marginRight: "5px" }}
              />
            )}
            <a href={cartelInfo.qid} target="_blank" rel="noopener noreferrer">
              {cartelInfo.cartel}
            </a>
          </p>
        </div>
      )}
    </>
  );
};

export default memo(Mapbox);
