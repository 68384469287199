import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './CategoryRow.module.css';

const categories = [
    { id: 8, name: 'Capital City', imgSrc: '/capitalcity.webp' },
    { id: 9, name: 'City', imgSrc: '/city.webp' },
    { id: 10, name: 'Town', imgSrc: '/town.webp' },
    { id: 11, name: 'Village', imgSrc: '/placeholder.webp' },
    { id: 12, name: 'Islands', imgSrc: '/placeholder.webp' },
    { id: 13, name: 'Harbours', imgSrc: '/placeholder.webp' },
    { id: 14, name: 'Ports', imgSrc: '/placeholder.webp' },
    { id: 15, name: 'Railways', imgSrc: '/placeholder.webp' },
    { id: 16, name: 'Airports', imgSrc: '/placeholder.webp' },
    { id: 17, name: 'Train Stations', imgSrc: '/placeholder.webp' },
    { id: 18, name: 'Lakes', imgSrc: '/placeholder.webp' },
    { id: 19, name: 'Mountains', imgSrc: '/placeholder.webp' },
    { id: 20, name: 'Rivers', imgSrc: '/placeholder.webp' },
    { id: 21, name: 'Volcanoes', imgSrc: '/placeholder.webp' },
    { id: 22, name: 'Skylines', imgSrc: '/placeholder.webp' },
    { id: 23, name: 'Landmarks', imgSrc: '/placeholder.webp' },
    { id: 24, name: 'Cultural Sites', imgSrc: '/placeholder.webp' },
    { id: 25, name: 'Historic Sites', imgSrc: '/placeholder.webp' },
    { id: 26, name: 'Iconic Views', imgSrc: '/placeholder.webp' },
    { id: 27, name: 'Hidden Gems', imgSrc: '/placeholder.webp' },
    { id: 28, name: 'Theme Parks', imgSrc: '/placeholder.webp' },
    { id: 29, name: 'Farms', imgSrc: '/placeholder.webp' },
    { id: 30, name: 'Parks', imgSrc: '/placeholder.webp' },
    { id: 31, name: 'Beaches', imgSrc: '/placeholder.webp' },
    { id: 32, name: 'Nightlife', imgSrc: '/placeholder.webp' },
    { id: 33, name: 'Urban', imgSrc: '/placeholder.webp' },
    { id: 34, name: 'Wildlife', imgSrc: '/placeholder.webp' },
    { id: 35, name: 'Zoos', imgSrc: '/placeholder.webp' },
    { id: 36, name: 'Animal Sanctuaries', imgSrc: '/placeholder.webp' },
    { id: 37, name: 'Weather', imgSrc: '/placeholder.webp' },
    { id: 38, name: 'News', imgSrc: '/placeholder.webp' },
];

const CategoryRow = () => {
    const [visibleCategories, setVisibleCategories] = useState(0);
    const [loadedImages, setLoadedImages] = useState({}); // Track loaded images
    const categoryRowRef = useRef(null);

    // Calculate the visible categories based on container width
    const calculateVisibleCategories = () => {
        if (categoryRowRef.current) {
            const containerWidth = categoryRowRef.current.offsetWidth;
            const itemWidth = 130; // Width of each category item
            const visibleItems = Math.floor(containerWidth / itemWidth);
            setVisibleCategories(visibleItems);
        }
    };

    // Handle image load to remove blur
    const handleImageLoad = (index) => {
        setLoadedImages((prevLoadedImages) => ({
            ...prevLoadedImages,
            [index]: true,
        }));
    };

    useEffect(() => {
        calculateVisibleCategories(); // Initial calculation
        window.addEventListener('resize', calculateVisibleCategories); // Recalculate on resize
        return () => window.removeEventListener('resize', calculateVisibleCategories);
    }, []);

    return (
        <div className={styles['category-section']}>
            <div className={styles['category-header']}>
                <h2 className={styles['category-title']}><span className="earf-blue">Live</span> Categories</h2>
                <Link to="/browse" className={styles['view-all-button']}>View All</Link>
            </div>

            <div className={styles['category-row']} ref={categoryRowRef}>
                {categories.slice(0, visibleCategories).map((category, index) => (
                    <Link
                        to={`/browse/${encodeURIComponent(category.name)}`}
                        className={styles['category-item']}
                        key={category.id}
                    >
                        <div className={styles['category-image-wrapper']}>
                            <img
                                src={category.imgSrc}
                                alt={category.name}
                                className={`${styles['category-image']} ${loadedImages[index] ? styles['loaded'] : styles['loading']}`}
                                loading="lazy"
                                onLoad={() => handleImageLoad(index)} // Handle image load
                            />
                            {!loadedImages[index] && <div className={styles['image-placeholder']}></div>}
                        </div>
                        <span className={styles['category-name']}>{category.name}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default CategoryRow;
