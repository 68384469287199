import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './GunViolence.css'; // Custom styling for gun violence alerts

const GunViolence = () => {
  const [gunViolenceList, setGunViolenceList] = useState([]);
  const [loading, setLoading] = useState(true); // Start with loading

  const navigate = useNavigate(); // Get navigate function from react-router-dom
  const apiKey = process.env.REACT_APP_EARF_API_KEY;

  // Fetch Gun Violence data
  const fetchGunViolenceData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://server.earf.tv/Gun-Violence/last-72-hours`, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
          'X-Request-Source': 'GunViolence',
        },
      });
      const data = await response.json();
      setGunViolenceList(data); // Set the full dataset
    } catch (error) {
      console.error('Error fetching gun violence data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGunViolenceData(); // Fetch all data on component mount
  }, []);

  const formatDate = (dateString) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()]; // Get month name from the months array
    const year = date.getFullYear();

    return `${day} ${month} ${year}`; // Example: "20 November 2024"
  };

  const handleGunFeatureClick = (latitude, longitude) => {
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    if (isNaN(lat) || isNaN(lng)) {
      console.error('Invalid coordinates:', latitude, longitude);
      return;
    }
  
    // Pass coordinates to the map URL (query parameters)
    navigate(`/explore?lat=${lat}&lng=${lng}`);
  };
  
  // Render the list of gun violence incidents
  const renderGunViolenceList = () => (
    <div className="gun-violence-list">
      {gunViolenceList.map((incident, index) => (
        <div key={index} className="gun-violence-card">
          <div className="gun-violence-card-content">
            <div className="gun-violence-info">
              <h3 className="gun-violence-title">
                {incident.address}, <span style={{ color: 'var(--earf-blue)' }}>{incident.city}, {incident.state}</span>
              </h3>
              <p className="gun-violence-details">
                <div style={{ textAlign: 'center' }}>
                  <img src="/gravestone.png" alt="Gravestone Icon" />
                  <span className="killed">{incident.killed}</span>
                  <span className="icon-label">Killed</span>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <img src="/hospital.png" alt="Hospital Icon" />
                  <span className="injured">{incident.injured}</span>
                  <span className="icon-label">Injured</span>
                </div>
              </p>
              <p className="gun-violence-date">{formatDate(incident.date)}</p>
            </div>
            <p
              className="coordinates"
              onClick={() =>handleGunFeatureClick (parseFloat(incident.latitude), parseFloat(incident.longitude))}
              style={{ cursor: 'pointer', color: 'var(--earf-blue)' }}
            >
              {incident.latitude}, {incident.longitude}
            </p>
            <a
              href={incident.operations.viewSource || incident.operations.viewIncident}
              target="_blank"
              rel="noopener noreferrer"
              className="gun-violence-more-info-button"
            >
              Read More
            </a>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <div className="header-container">
        <h2>USA Gun Violence</h2>
        <p>(Last 72 Hours)</p>
      </div>
      {loading ? (
        <div className="full-container">
          <div className="spinner-wrapper">
            <div className="spinner"></div>
          </div>
        </div>
      ) : (
        renderGunViolenceList()
      )}
    </div>
  );
};

export default GunViolence;
