import React, { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./RadioBrowse.css";
import { Helmet } from "react-helmet-async";
import Facts from "../Facts/Facts";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const slugify = (text) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");
};

const RadioBrowse = () => {
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Loader state
  const navigate = useNavigate();
  const errorShown = useRef(false);
  
  useEffect(() => {
    fetch("https://de2.api.radio-browser.info/json/countries", {
      method: "GET",
      headers: {
        "User-Agent": "Earf.tv/Beta"
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter(
          (country) => country.iso_3166_1 !== "AN"
        );
        const sortedCountries = filteredData.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCountries(sortedCountries);
        setFilteredCountries(sortedCountries);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("❌ Error fetching countries:", error);
        setIsLoading(false);
        if (!errorShown.current) {
          toast.error('Radio services are currently unavailable. Please try again later.', {
            position: "top-center",
            autoClose: 4000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: false
          });
          errorShown.current = true;
        }
      });
  }, []);
  

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const results = countries.filter((country) =>
      country.name.toLowerCase().includes(query)
    );
    setFilteredCountries(results);
  };

  const handleImageLoad = (event) => {
    event.target.classList.remove("blur"); // Remove blur class once image is loaded
  };

  return (
    <div className="content-wrapper">
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>Browse Radio | Earf</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Explore live radio stations from around the world on Earf. Listen to a variety of genres and discover local and global broadcasts all in one place."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/radio" />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="Browse Radio | Earf" />
        <meta
          property="og:description"
          content="Tune in to live radio stations from every corner of the globe on Earf. Discover your favorite genres and explore local and international radio."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv/radio" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="929645909242200" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Browse Radio | Earf" />
        <meta
          name="twitter:description"
          content="Discover live radio stations worldwide on Earf.tv. Listen to top broadcasts and genres from both local and international stations."
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf Logo" />
      </Helmet>

      <div className="radio-header">
        <h2 className="radio-browse-title">
          Browse{" "}
          <span style={{ color: "rgba(104, 223, 223, 0.733)" }}>Radio</span>
        </h2>
      </div>
      {isLoading ? (
        <div className="full-container">
          <div className="spinner-wrapper">
            <div className="spinner-content">
              <img
                className="image-spinner"
                src="/metaimage.png"
                alt="Loading"
              />
              <div className="browse-loading-text">Loading Countries</div>
            </div>
          </div>
          <Facts />
          {/* Loader Footer */}
          <footer className="loader-footer">
            <div className="footer-content">
              <p>
                Is your stream on <span className="earf-highlight">EARF</span>?
                Want to get verified?{" "}
                <img
                  src="/partner.png"
                  alt="Verified Partner"
                  aria-label="Verified Partner"
                  className="tick-icon"
                />{" "}
                <Link to="/how-to-get-verified" className="loading-footer-link">
                  {" "}
                  Learn How
                </Link>
              </p>
            </div>
          </footer>
        </div>
      ) : (
        <div className="scrollable-content">
          <div className="country-list">
            {filteredCountries.map((country) => (
              <div
                key={country.iso_3166_1}
                className="radio-country-item"
                onClick={() => navigate(`/radio/${slugify(country.name)}`)}
              >
                <img
                  src={`https://flagcdn.com/w320/${country.iso_3166_1.toLowerCase()}.png`}
                  alt={`${country.name} flag`}
                  className="flag-icon blur" // Add the blur class
                  loading="lazy" // Lazy loading
                  onLoad={handleImageLoad} // Remove blur when image is loaded
                />
                <div className="country-info">
                  <div className="country-name">{country.name}</div>
                  <div className="station-count">
                    {country.stationcount} Stations
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RadioBrowse;
