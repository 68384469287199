import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./HowToGetVerified.css";

const HowToGetVerified = () => {
  return (
    <div className="verify-content">
      <div className="verify-container">
        <Helmet prioritizeSeoTags>
          {/* Page Title */}
          <title>How to Get Verified | Earf</title>

          {/* Meta Description */}
          <meta
            name="description"
            content="Follow our step-by-step verification process to get your channel and stream(s) verified on Earf. Discover how to securely showcase your stream and earn the verified tick."
          />

          {/* Canonical Tag */}
          <link rel="canonical" href="https://earf.tv/how-to-get-verified" />

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content="How to Get Verified | Earf" />
          <meta
            property="og:description"
            content="Learn how to get your channel and stream(s) verified on Earf. Our simple, secure process ensures your stream meets our guidelines and earns you the verified tick to boost credibility."
          />
          <meta property="og:image" content="https://earf.tv/Earf-E.png" />
          <meta
            property="og:url"
            content="https://earf.tv/how-to-get-verified"
          />
          <meta property="og:type" content="website" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="fb:app_id" content="929645909242200" />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="How to Get Verified | Earf" />
          <meta
            name="twitter:description"
            content="Discover our step-by-step process to verify your channel and stream(s) on Earf. Secure your verified tick and enhance your presence on our platform."
          />
          <meta name="twitter:image" content="https://earf.tv/Earf-E.png" />
          <meta name="twitter:image:alt" content="Earf Verification Process" />
        </Helmet>

        <h1>
          <img src="/Earf-E.png" alt="Earf Logo" className="verify-logo" />
          Earf Verification Process
        </h1>

        {/* Notice about camera request process */}
        <div className="verify-notice">
          <p>
            <strong>Notice:</strong> If you do not currently have a camera on
            our site, please complete the{" "}
            <Link to="/camera-request" className="verify-email">
              Camera Request
            </Link>{" "}
            process before proceeding with verification.
          </p>
        </div>

        {/* Important Verification Disclaimer */}
        <div className="verify-notice1">
          <p>
            Please note that the verification process and Verified Partner
            status are provided exclusively by{" "}
            <span className="earf-highlight">EARF</span>. You are required to
            prove ownership of your YouTube channel to host Verified Streams on
            our platform, this verification is in no way affiliated with or
            endorsed by YouTube. <br />
            It is an Earf.tv Verification and you would be a Verified Partner{" "}
            <img
              src="/partner.png"
              alt="Verified Partner Icon"
              className="partner-icon"
            />{" "}
            and have a Verified Tick next to the Clickable URL Channel name on
            each Stream.
          </p>
        </div>
        <div className="verify-benefits">
          <h4>Benefits of Getting Verified</h4>
          <ul>
            <li>
              Users know they are interacting with a trusted, verified source,
              ensuring credibility and authenticity.
            </li>
            <li>
              Verified channels are more likely to have their streams featured
              on the front page carousel, increasing visibility and audience
              reach.
            </li>
            <li>
              Early verification is free—before we transition to a one-time
              payment system to support server costs and platform
              sustainability. Channels verified early will be exempt from this
              future payment.
            </li>
            <li>
              Verified channels stand out with a unique verification badge,
              making it easier for users to recognize legitimate and official
              streams.
            </li>
            <li>
              Partnered verified channels may receive priority support and
              troubleshooting assistance to ensure a smooth streaming
              experience.
            </li>
            <li>
              Future opportunities for exclusive features and partnerships may
              be offered to verified channels as the platform expands.
            </li>
            <li>
              Verification helps prevent impersonation or misleading streams,
              protecting both content creators and viewers.
            </li>
          </ul>
        </div>

        <h3>
          To have your channel and stream(s) verified on{" "}
          <span className="earf-highlight">EARF</span>, please follow these
          steps:
        </h3>

        <ol>
          <li>
            <strong>Review Our Guidelines:</strong>
            <br />
            Make sure your stream meets our content and technical requirements.
            If your stream is already listed, it likely adheres to these
            guidelines—we simply need to confirm ownership.
          </li>
          <li>
            <strong>Prove Channel Ownership:</strong>
            <br />
            Provide evidence that you own the associated YouTube channel. You
            can do this by:
            <ul>
              <li>
                Sending a clear screenshot of your channel’s edit video page
                (with any sensitive information removed), or
              </li>
              <li>
                Sending an email from an address closely linked to your YouTube
                channel, business, or any website associated with your channel.
              </li>
            </ul>
          </li>
          <li>
            <strong>Include Additional Details:</strong>
            <br />
            In your email to{" "}
            <a href="mailto:partners@earf.tv" className="verify-email">
              partners@earf.tv
            </a>
            , please include:
            <ul>
              <li>Your name</li>
              <li>Contact email(s)</li>
              <li>Camera name and location</li>
              <li>A link to your stream (if it’s already live on our site)</li>
              <li>Your channel URL and any related social media profiles</li>
              <li>
                A brief statement confirming your consent to have your stream(s)
                featured on Earf
              </li>
              <li>
                Any corrections or updates you’d like for your stream or stream
                listings
              </li>
            </ul>
          </li>
          <li>
            <strong>Send Your Verification Request:</strong>
            <br />
            Email the above details to{" "}
            <a href="mailto:partners@earf.tv" className="verify-email">
              partners@earf.tv
            </a>
            . Our team will review your request and respond with further
            instructions or a final decision.
          </li>
        </ol>

        <p>
          We will never request personal information beyond names, emails,
          camera locations, and any additional details mentioned on our{" "}
          <Link to="/camera-request" className="verify-email">
            Camera Request
          </Link>{" "}
          page. We may ask general questions about the area or camera, but{" "}
          <strong>
            we will never ask for any ID documents or similar personal items.
          </strong>
        </p>

        <p className="verification-note">
          The verification process is completely free during the initial
          rollout, though a one-time payment per channel may be required in the
          future upon approval. Channels that are already verified will be
          exempt.
        </p>

        <p>
          For any questions or assistance, please contact our support team at{" "}
          <a href="mailto:support@earf.tv" className="verify-email">
            support@earf.tv
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default HowToGetVerified;
