import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./Contact.css"; // Optional CSS for styling
import { FaDiscord, FaFacebook, FaTwitter } from "react-icons/fa"; // Importing social media icons
import Footer from "../Footer/Footer";

const Contact = () => {
  return (
    <div className="content-wrapper">
      <Helmet prioritizeSeoTags>
        <title>Contact Us | Earf</title>
        <meta
          name="description"
          content="If you need to contact us, please use the provided avenues. The contact page is currently undergoing heavy changes."
        />
        <link rel="canonical" href="https://earf.tv/contact" />
        <meta property="og:title" content="Contact Us | Earf" />
        <meta
          property="og:description"
          content="If you need to contact us, please use the provided avenues. The contact page is currently undergoing heavy changes."
        />
        <meta property="og:image" content="https://earf.tv/Earf-E.png" />
        <meta property="og:url" content="https://earf.tv/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="929645909242200" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Contact Us | Earf" />
        <meta
          name="twitter:description"
          content="If you need to contact us, please use the provided avenues. The contact page is currently undergoing heavy changes."
        />
        <meta name="twitter:image" content="https://earf.tv/Earf-E.png" />
        <meta name="twitter:image:alt" content="Earf Logo" />
      </Helmet>

      <div className="contact-container">
        <h1 className="contact-header">
          <img src="/Earf-E.png" alt="Earf Logo" className="contact-logo" />{" "}
          Contact Us
        </h1>

        <div className="main-contact-message">
          <h3>
            Our contact page is currently undergoing significant updates, and it
            may take some time to complete. In the meantime, if you need to
            reach us, please use the following contact method:
          </h3>
          <ul>
            <li>
              General Support:{" "}
              <a href="mailto:support@earf.tv">support@earf.tv</a>
            </li>
            <li>
              Partner and Partnership Inquiries:{" "}
              <a href="mailto:partners@earf.tv">partners@earf.tv</a>
            </li>
            <li>
              Legal or DMCA Copyright Issues:{" "}
              <a href="mailto:legal@earf.tv">legal@earf.tv</a>
            </li>
          </ul>

          {/* Additional Links */}
          <p className="additional-links">
            For camera submissions, please visit our{" "}
            <Link to="/camera-request" className="contact-link">
              Camera Request
            </Link>{" "}
            page, and for verification inquiries, please visit our{" "}
            <Link to="/how-to-get-verified" className="contact-link">
              Verification
            </Link>{" "}
            page.
          </p>
        </div>
        <h4>
          We greatly appreciate your understanding and patience as we work to
          improve this platform!
        </h4>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
