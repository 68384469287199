// src/pages/AdminDashboard.js

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminContext } from '../AdminContext';
import { Helmet } from 'react-helmet-async';
import ServerStatus from './components/ServerStatus/ServerStatus';
import ServerLog from './components/ServerLog/ServerLog';

import styles from './AdminDashboard.module.css'; // CSS modules

const AdminDashboard = () => {
  const { logoutAdmin, isAdminAuthenticated } = useContext(AdminContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate('/admin/login');
    }
  }, [isAdminAuthenticated, navigate]);

  const handleLogout = () => {
    logoutAdmin();
    navigate('/admin/login');
  };

  if (!isAdminAuthenticated) {
    return <div className="spinner-wrapper">
      <div className="spinner"></div>
    </div>;
  }

  return (
    <div className={styles['dashboard-container']}>
      <Helmet prioritizeSeoTags>
        <title>Admin Dashboard | Earf Admin</title>
        <meta name="description" content="Admin Dashboard for Earf.TV. Access admin tools and manage streams." />
        <meta property="og:image" content="https://earf.tv/Earf-E.png" />
        <meta name="twitter:image" content="https://earf.tv/Earf-E.png" />
      </Helmet>



      <div className={styles['dashboard-header']}>

        <div className={styles['title-container']}>
          <img src="/Earf-E.png" alt="Admin Logo" className={styles['dashboard-logo']} />
          <h2 className={styles['dashboard-title']}>
            <span className={styles['admin-highlight']}>Admin</span> Dashboard
          </h2>
        </div>


        <button className={styles['logout-button']} onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className={styles['dashboard-items']}>
        <div className={styles['dashboard-item']} onClick={() => navigate('/admin/feature-editor')}>
          <img src="/youtube.png" alt="Stream Checker Icon" className={styles['item-icon']} />
          <div className={styles['item-content']}>
            <h3>Feature Editor</h3>
            <p>View status of all features and perform changes</p>
          </div>
        </div>


        <div className={styles['dashboard-item']} onClick={() => navigate('/admin/feature-creator')}>
          <img src="/youtube.png" alt="Stream Creator Icon" className={styles['item-icon']} />
          <div className={styles['item-content']}>
            <h3>Feature Creator</h3>
            <p>Create new features</p>
          </div>
        </div>

        <div
          className={styles['dashboard-item']}
          onClick={() => navigate('/admin/country-profiles')}
        >
          <img src="/Earf-E.png" alt="Country Profiles Icon" className={styles['item-icon']} />
          <div className={styles['item-content']}>
            <h3>Country Profiles</h3>
            <p>Manage and edit country-specific data and configurations</p>
          </div>
        </div>

      </div>
      <ServerStatus />
      <ServerLog />

    </div>
  );
};

export default AdminDashboard;
