import React, { useState, useEffect } from "react";
import VideoSection from "../VideoSection/VideoSection";
import FeatureRow from "../FeatureRow/FeatureRow";
import CategoryRow from "../CategoryRow/CategoryRow";
import CountryRow from "../CountryRow/CountryRow";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [videoData, setVideoData] = useState([]); // State for video data

  // Map of tag1 categories to description templates
  const descriptionTemplates = {
    // News
    News: (name, wikidata) =>
      `Stay in the know with the latest updates from ${name}, your go-to source for breaking news in ${wikidata}. Catch every moment live on <span class='earf-blue'>Earf</span>.`,
    BreakingNews: (name, wikidata) =>
      `Be the first to know about breaking news happening around ${name} in ${wikidata}. Stay updated live on <span class='earf-blue'>Earf</span>.`,
    Weather: (name, wikidata) =>
      `Get the latest weather updates from ${name}, bringing you the forecast from ${wikidata}. Stay prepared live on <span class='earf-blue'>Earf</span>.`,
    LiveEvents: (name, wikidata) =>
      `Feel the pulse of live events at ${name}, a premier venue in ${wikidata}. Watch history unfold live on <span class='earf-blue'>Earf</span>.`,
    StormWatch: (name, wikidata) =>
      `Stay informed with storm warnings and weather alerts in ${name}, an area prone to extreme weather in ${wikidata}. Watch live on <span class='earf-blue'>Earf</span>.`,
    Sports: (name, wikidata) =>
      `Dive into the thrill of live sports action at ${name}, located in ${wikidata}. Don't miss a moment of the excitement on <span class='earf-blue'>Earf</span>.`,
    Festivals: (name, wikidata) =>
      `Celebrate the vibrant culture of ${name}, a shining example of ${wikidata}'s festivals. Experience the energy live on <span class='earf-blue'>Earf</span>.`,
    Parades: (name, wikidata) =>
      `Join the excitement of parades in ${name}, showcasing the rich traditions of ${wikidata}. Watch the parade live on <span class='earf-blue'>Earf</span>.`,
    Protests: (name, wikidata) =>
      `Witness social movements unfold in ${name}, as protests spark change in ${wikidata}. Follow the action live on <span class='earf-blue'>Earf</span>.`,
    Conferences: (name, wikidata) =>
      `Dive into the latest discussions and ideas at conferences in ${name}, a hub for innovation in ${wikidata}. Watch the conference live on <span class='earf-blue'>Earf</span>.`,
    Christmas: (name, wikidata) =>
      `Celebrate the festive season at ${name}, a magical location in ${wikidata} where Christmas comes alive. Watch the celebrations live on <span class='earf-blue'>Earf</span>.`,
    NewYearsEve: (name, wikidata) =>
      `Ring in the new year with celebrations at ${name}, a prime spot in ${wikidata} for unforgettable New Year’s Eve parties. Watch it live on <span class='earf-blue'>Earf</span>.`,
    RollingCams: (name, wikidata) =>
      `Explore real-time views of ${name}, showcasing live rolling cams in ${wikidata}. Catch every moment live on <span class='earf-blue'>Earf</span>.`,

    // Tours
    WalkingTour: (name, wikidata) =>
      `Take a scenic walking tour through ${name}, a captivating gem in ${wikidata}. Explore it like never before, live on <span class='earf-blue'>Earf</span>.`,
    DrivingTour: (name, wikidata) =>
      `Embark on a breathtaking driving tour of ${name}, offering panoramic views of ${wikidata}. Experience the journey live on <span class='earf-blue'>Earf</span>.`,
    DroneTour: (name, wikidata) =>
      `Soar high above ${name}, offering stunning aerial views of ${wikidata}. Immerse yourself in this captivating drone tour, live on <span class='earf-blue'>Earf</span>.`,
    HistoricalTour: (name, wikidata) =>
      `Step back in time with a historical tour of ${name}, exploring the rich heritage of ${wikidata}. Discover the past live on <span class='earf-blue'>Earf</span>.`,
    FoodTour: (name, wikidata) =>
      `Savor the flavors of ${name} with a food tour through the best eateries in ${wikidata}. Taste the local cuisine live on <span class='earf-blue'>Earf</span>.`,
    VirtualRealityTour: (name, wikidata) =>
      `Experience the future with a virtual reality tour of ${name}, bringing ${wikidata} to life in an immersive 3D world. Explore it live on <span class='earf-blue'>Earf</span>.`,
    CityTours: (name, wikidata) =>
      `Uncover the hidden gems of ${name} with a guided city tour through ${wikidata}. See the sights live on <span class='earf-blue'>Earf</span>.`,
    NatureTours: (name, wikidata) =>
      `Discover the beauty of nature on a tour of ${name}, exploring the natural wonders of ${wikidata}. Experience it live on <span class='earf-blue'>Earf</span>.`,
    CulturalTours: (name, wikidata) =>
      `Immerse yourself in the culture of ${name}, a journey through the rich traditions of ${wikidata}. See it live on <span class='earf-blue'>Earf</span>.`,

    // Urban
    CapitalCity: (name, wikidata) =>
      `Discover the bustling heart of ${wikidata} with ${name}, an iconic capital city. Experience its energy live on <span class='earf-blue'>Earf</span>.`,
    City: (name, wikidata) =>
      `Explore the dynamic streets of ${name}, a vibrant city nestled in ${wikidata}. Experience its charm live on <span class='earf-blue'>Earf</span>.`,
    Village: (name, wikidata) =>
      `Step into the peaceful embrace of ${name}, a picturesque village in ${wikidata}. Discover its serene beauty live on <span class='earf-blue'>Earf</span>.`,
    Town: (name, wikidata) =>
      `Unveil the charm of ${name}, a delightful town in ${wikidata}. Experience its unique vibe live on <span class='earf-blue'>Earf</span>.`,
    Urban: (name, wikidata) =>
      `Immerse yourself in the lively rhythm of ${name}, a bustling urban oasis in ${wikidata}. Watch it all unfold live on <span class='earf-blue'>Earf</span>.`,
    Skylines: (name, wikidata) =>
      `Gaze in awe at the magnificent skyline of ${name}, one of the jewels of ${wikidata}. Watch the sunset live on <span class='earf-blue'>Earf</span>.`,
    Nightlife: (name, wikidata) =>
      `Experience the vibrant nightlife of ${name}, a hotspot in ${wikidata}. Watch the city come alive after dark, live on <span class='earf-blue'>Earf</span>.`,
    Harbours: (name, wikidata) =>
      `Set sail through the scenic harbour of ${name}, a lively port in ${wikidata}. Watch the activity live on <span class='earf-blue'>Earf</span>.`,
    TrainStations: (name, wikidata) =>
      `Discover the bustling atmosphere of ${name}, a vibrant train station in ${wikidata}. Watch the energy unfold live on <span class='earf-blue'>Earf</span>.`,
    Railways: (name, wikidata) =>
      `Journey through the scenic railways of ${name}, nestled in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Island: (name, wikidata) =>
      `Escape to the idyllic ${name}, a stunning island paradise in ${wikidata}. Watch its beauty live on <span class='earf-blue'>Earf</span>.`,
    Airports: (name, wikidata) =>
      `Explore the excitement and movement of ${name}, a major airport in ${wikidata}. Watch the activity live on <span class='earf-blue'>Earf</span>.`,
    StreetMarkets: (name, wikidata) =>
      `Experience the vibrant hustle and bustle of ${name}, a lively street market in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Suburbs: (name, wikidata) =>
      `Discover the charm of ${name}, a serene suburb in ${wikidata}. Watch its peaceful streets live on <span class='earf-blue'>Earf</span>.`,
    UrbanParks: (name, wikidata) =>
      `Take a walk through ${name}, a peaceful urban park in ${wikidata}. Explore the tranquility live on <span class='earf-blue'>Earf</span>.`,
    ShoppingDistricts: (name, wikidata) =>
      `Browse the stylish shops of ${name}, a bustling shopping district in ${wikidata}. Experience it live on <span class='earf-blue'>Earf</span>.`,
    Bar: (name, wikidata) =>
      `Relax at ${name}, a popular bar in ${wikidata}. Enjoy the lively atmosphere live on <span class='earf-blue'>Earf</span>.`,
    Barbershop: (name, wikidata) =>
      `Step into ${name}, a classic barbershop in ${wikidata}. Watch the precision and style unfold live on <span class='earf-blue'>Earf</span>.`,
    HotelsResorts: (name, wikidata) =>
      `Indulge in luxury at ${name}, an exquisite hotel and resort in ${wikidata}. Explore the amenities live on <span class='earf-blue'>Earf</span>.`,
    Traffic: (name, wikidata) =>
      `Monitor the flow of traffic in ${name}, a city in ${wikidata} known for its busy roads. Stay updated live on <span class='earf-blue'>Earf</span>.`,
    Construction: (name, wikidata) =>
      `Watch the construction of ${name}, a booming development in ${wikidata}. See progress live on <span class='earf-blue'>Earf</span>.`,

    // Nature
    Wildlife: (name, wikidata) =>
      `Venture into the wild at ${name}, where nature thrives in ${wikidata}. Experience the incredible wildlife live on <span class='earf-blue'>Earf</span>.`,
    Animals: (name, wikidata) =>
      `Get an up-close look at the fascinating animals of ${name}, a wildlife sanctuary in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    BirdFeeders: (name, wikidata) =>
      `Observe the birds flocking to the feeders at ${name}, a birdwatcher’s paradise in ${wikidata}. Watch the peaceful scene live on <span class='earf-blue'>Earf</span>.`,
    Beaches: (name, wikidata) =>
      `Unwind with stunning views of ${name}, a pristine beach in ${wikidata}. Feel the breeze live on <span class='earf-blue'>Earf</span>.`,
    Mountains: (name, wikidata) =>
      `Stand in awe of ${name}, a majestic mountain in ${wikidata}, offering panoramic vistas. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Lakes: (name, wikidata) =>
      `Find tranquility by the shimmering waters of ${name}, a picturesque lake in ${wikidata}. Experience it live on <span class='earf-blue'>Earf</span>.`,
    Rivers: (name, wikidata) =>
      `Follow the course of ${name}, a stunning river in ${wikidata}. Watch its flow live on <span class='earf-blue'>Earf</span>.`,
    Waterfalls: (name, wikidata) =>
      `Marvel at the cascading beauty of ${name}, a spectacular waterfall in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Countryside: (name, wikidata) =>
      `Breathe in the serenity of ${name}, a peaceful countryside haven in ${wikidata}. Take in the natural beauty live on <span class='earf-blue'>Earf</span>.`,
    OceanViews: (name, wikidata) =>
      `Dive into the vastness of the ocean at ${name}, a breathtaking view in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Rainforests: (name, wikidata) =>
      `Journey into the lush greenery of ${name}, a vibrant rainforest in ${wikidata}. Watch its wonders live on <span class='earf-blue'>Earf</span>.`,
    Forests: (name, wikidata) =>
      `Explore the rich biodiversity of ${name}, a sprawling forest in ${wikidata}. Experience its wonders live on <span class='earf-blue'>Earf</span>.`,
    NaturalReserves: (name, wikidata) =>
      `Discover the untouched beauty of ${name}, a natural reserve in ${wikidata}. Watch its pristine landscape live on <span class='earf-blue'>Earf</span>.`,
    Gardens: (name, wikidata) =>
      `Stroll through the peaceful gardens of ${name}, a serene escape in ${wikidata}. Experience its beauty live on <span class='earf-blue'>Earf</span>.`,
    Farms: (name, wikidata) =>
      `Experience the simplicity of ${name}, a charming farm in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    CoralReefs: (name, wikidata) =>
      `Dive into the vibrant underwater world of ${name}, a stunning coral reef in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Deserts: (name, wikidata) =>
      `Explore the mysterious beauty of ${name}, a vast desert in ${wikidata}. Watch its wonders live on <span class='earf-blue'>Earf</span>.`,
    Savannas: (name, wikidata) =>
      `Venture into the vast savanna of ${name}, a unique ecosystem in ${wikidata}. Watch its wildlife live on <span class='earf-blue'>Earf</span>.`,
    Wetlands: (name, wikidata) =>
      `Explore the rich biodiversity of ${name}, a thriving wetland in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Glaciers: (name, wikidata) =>
      `Marvel at the icy beauty of ${name}, a massive glacier in ${wikidata}. Watch its movements live on <span class='earf-blue'>Earf</span>.`,
    HotSprings: (name, wikidata) =>
      `Relax by the natural hot springs of ${name}, a rejuvenating escape in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Volcanoes: (name, wikidata) =>
      `Witness the power of ${name}, a majestic volcano in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Caves: (name, wikidata) =>
      `Explore the mysterious depths of ${name}, a fascinating cave system in ${wikidata}. Discover its wonders live on <span class='earf-blue'>Earf</span>.`,
    Fishing: (name, wikidata) =>
      `Experience the thrill of fishing at ${name}, a premier fishing destination in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Surf: (name, wikidata) =>
      `Catch the perfect wave at ${name}, a surfing hotspot in ${wikidata}. Watch the surfers live on <span class='earf-blue'>Earf</span>.`,

    // Other
    Landmarks: (name, wikidata) =>
      `Journey through time and culture with ${name}, a landmark in ${wikidata} that embodies history. Experience it live on <span class='earf-blue'>Earf</span>.`,
    ThemeParks: (name, wikidata) =>
      `Get ready for thrills at ${name}, a theme park packed with adventure in ${wikidata}. Join the fun live on <span class='earf-blue'>Earf</span>.`,
    CulturalSites: (name, wikidata) =>
      `Dive into the heart of heritage at ${name}, a cultural treasure in ${wikidata}. Watch it come to life live on <span class='earf-blue'>Earf</span>.`,
    HistoricSites: (name, wikidata) =>
      `Walk through history at ${name}, a timeless historic site in ${wikidata}. Experience the past live on <span class='earf-blue'>Earf</span>.`,
    HiddenGems: (name, wikidata) =>
      `Uncover ${name}, a hidden gem waiting to be explored in ${wikidata}. Discover its charm live on <span class='earf-blue'>Earf</span>.`,
    LocalFavorites: (name, wikidata) =>
      `Experience the vibrant local charm of ${name}, a beloved favorite in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    IconicViews: (name, wikidata) =>
      `Take in the breathtaking views from ${name}, a truly iconic spot in ${wikidata}. Experience it live on <span class='earf-blue'>Earf</span>.`,
    FamilyFriendly: (name, wikidata) =>
      `Enjoy the family-friendly activities at ${name}, a destination for all ages in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    Zoos: (name, wikidata) =>
      `Get up-close to amazing animals at ${name}, a world-class zoo in ${wikidata}. Watch them live on <span class='earf-blue'>Earf</span>.`,
    SkiResorts: (name, wikidata) =>
      `Hit the slopes at ${name}, a premier ski resort in ${wikidata}. Experience the action live on <span class='earf-blue'>Earf</span>.`,
    Aquariums: (name, wikidata) =>
      `Discover marine life wonders at ${name}, an incredible aquarium located in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    BotanicalGardens: (name, wikidata) =>
      `Stroll through the breathtaking botanical gardens at ${name}, a lush paradise in ${wikidata}. Explore it live on <span class='earf-blue'>Earf</span>.`,
    AnimalSanctuaries: (name, wikidata) =>
      `Support wildlife at ${name}, a dedicated animal sanctuary in ${wikidata}. Watch the animals live on <span class='earf-blue'>Earf</span>.`,
    Ports: (name, wikidata) =>
      `Explore the lively port at ${name}, a bustling harbor in ${wikidata}. Watch the ships dock live on <span class='earf-blue'>Earf</span>.`,
    ArtGalleries: (name, wikidata) =>
      `Appreciate the stunning works of art at ${name}, a renowned art gallery in ${wikidata}. Watch the masterpieces live on <span class='earf-blue'>Earf</span>.`,
    Museums: (name, wikidata) =>
      `Step into the world of knowledge at ${name}, a top-tier museum in ${wikidata}. Explore its exhibits live on <span class='earf-blue'>Earf</span>.`,
    Fairs: (name, wikidata) =>
      `Join the festivities at ${name}, a fun-filled fair in ${wikidata}. Experience the excitement live on <span class='earf-blue'>Earf</span>.`,
    Observatories: (name, wikidata) =>
      `Stargaze at ${name}, a leading observatory in ${wikidata}. Watch the night sky live on <span class='earf-blue'>Earf</span>.`,
    Lighthouses: (name, wikidata) =>
      `Stand tall at ${name}, a historic lighthouse in ${wikidata}. Watch the beam shine live on <span class='earf-blue'>Earf</span>.`,
    Monuments: (name, wikidata) =>
      `Marvel at the majestic monument of ${name}, a testament to ${wikidata}'s history. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Castles: (name, wikidata) =>
      `Explore the grandeur of ${name}, a royal castle in ${wikidata}. Experience the majesty live on <span class='earf-blue'>Earf</span>.`,
    Bridges: (name, wikidata) =>
      `Admire the architectural wonder of ${name}, a breathtaking bridge in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Fountains: (name, wikidata) =>
      `Relax by the tranquil fountains of ${name}, an elegant spot in ${wikidata}. Watch it live on <span class='earf-blue'>Earf</span>.`,
    Astronomy: (name, wikidata) =>
      `Journey into the stars at ${name}, a top astronomy spot in ${wikidata}. Explore the cosmos live on <span class='earf-blue'>Earf</span>.`,
    Gaming: (name, wikidata) =>
      `Get in the game at ${name}, a thrilling gaming event in ${wikidata}. Watch the action unfold live on <span class='earf-blue'>Earf</span>.`,
    CruiseShips: (name, wikidata) =>
      `Set sail from ${name}, a bustling port for cruise ships in ${wikidata}. Watch the ships embark live on <span class='earf-blue'>Earf</span>.`,
    Religion: (name, wikidata) =>
      `Explore the sacred sites of ${name}, a spiritual destination in ${wikidata}. Experience the tranquility live on <span class='earf-blue'>Earf</span>.`,
    Church: (name, wikidata) =>
      `Step into the peaceful atmosphere of ${name}, a historic church in ${wikidata}. Watch the service live on <span class='earf-blue'>Earf</span>.`,
    Square: (name, wikidata) =>
      `Experience the heartbeat of the city at ${name}, a central square in ${wikidata}. Watch the crowd live on <span class='earf-blue'>Earf</span>.`,
    ArtificialIntelligence: (name, wikidata) =>
      `Explore the cutting-edge world of ${name}, an AI hub in ${wikidata}. Watch the future unfold live on <span class='earf-blue'>Earf</span>.`,
    Business: (name, wikidata) =>
      `Take a closer look at ${name}, a business hotspot in ${wikidata}. Watch industry leaders in action live on <span class='earf-blue'>Earf</span>.`,

    // Default
    Default: (name, wikidata) =>
      `Explore ${name}, located in ${wikidata}. Discover its charm live on <span class='earf-blue'>Earf</span>.`,
  };
  // Function to generate a description dynamically based on tag1
  const generateDescription = (tag1, name, wikidata) => {
    const template = descriptionTemplates[tag1] || descriptionTemplates.Default;
    return template(name, wikidata);
  };

  const fetchVideos = async () => {
    try {
      const liveResponse = await fetch("https://server.earf.tv/live-cameras", {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_EARF_API_KEY,
          "Content-Type": "application/json",
          "X-Request-Source": "Video-Section-Live",
        },
      });

      if (!liveResponse.ok) {
        throw new Error("Failed to fetch video data.");
      }

      const { features } = await liveResponse.json();

      // Filter to include only features with status 'online'
      const onlineFeatures = features.filter(
        (feature) =>
          feature.properties &&
          feature.properties.status &&
          feature.properties.status.toLowerCase() === "online"
      );

      // Ensure unique videos by `wikidata` (if available) or `videoId`
      const uniqueVideos = [];
      const usedKeys = new Set();
      const partnerStreams = [];

      onlineFeatures.forEach((feature) => {
        const { properties } = feature;

        // Use `wikidata` if available; fall back to `videoId` for uniqueness
        const uniqueKey = properties.wikidata || properties.videoId;

        if (!usedKeys.has(uniqueKey)) {
          const video = {
            id: properties.videoId,
            title: properties.name,
            youtubeId: properties.videoId, // Assuming videoId is YouTube's video ID
            thumbnail: `https://img.youtube.com/vi/${properties.videoId}/hqdefault.jpg`,
            wikidata: properties.wikidata,
            tags: [properties.tag1, properties.tag2, properties.tag3].filter(Boolean),
            partner: properties.partner, // Include partner property
            description: properties.manualdescription || generateDescription(
              properties.tag1,
              properties.name,
              properties.wikidata
            ),
          };

          if (properties.partner === 'YES') {
            partnerStreams.push(video);
          } else {
            uniqueVideos.push(video);
          }

          usedKeys.add(uniqueKey); // Mark this `wikidata` or `videoId` as used
        }
      });

      // Shuffle partner streams to ensure randomness
      const shuffledPartnerStreams = partnerStreams.sort(() => Math.random() - 0.5);

      // Select a random number (1-3) of partner streams
      const numPartnerStreams = Math.floor(Math.random() * 3) + 1;
      const selectedPartnerStreams = shuffledPartnerStreams.slice(0, numPartnerStreams);
      const remainingSlots = 10 - selectedPartnerStreams.length;
      const selectedVideos = [...selectedPartnerStreams, ...uniqueVideos.sort(() => Math.random() - 0.5).slice(0, remainingSlots)];

      // Shuffle the final unique videos
      const shuffledVideos = selectedVideos.sort(() => Math.random() - 0.5);

      setVideoData(shuffledVideos); // Set the video data for VideoSection
    } catch (error) {
      console.error("Error fetching videos:", error.message);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <div className="home-page-container">
      <Helmet prioritizeSeoTags>
        <title>Earf</title>
        <meta name="author" content="Earf Team" />
        <meta
          name="keywords"
          content="live earth cameras, global radio, weather updates, Earf, live streaming, global broadcasts, Earth live feed, Earf.tv, Earf, Interactive.Earth, Interactive, Earth"
        />
        <link rel="canonical" href="https://earf.tv" />
        <meta property="og:title" content="Earf" />
        <meta property="fb:app_id" content="929645909242200" />

        <meta
          property="og:description"
          content="Earf is an interactive live-streaming platform showcasing Earth's beauty through live cameras, global radio, real-time weather, and more. There’s something for everyone to explore on Earf."
        />
        <meta property="og:image" content="https://earf.tv/Earf-E.png" />
        <meta property="og:url" content="https://earf.tv" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Earf" />
        <meta
          name="twitter:description"
          content="Earf is an interactive live-streaming platform showcasing Earth's beauty through live cameras, global radio, real-time weather, and more."
        />
        <meta name="twitter:image" content="https://earf.tv/Earf-E.png" />
        <meta name="twitter:image:alt" content="Earf Logo" />
        <meta
          name="description"
          content="Earf is an interactive live-streaming platform showcasing Earth's beauty through live cameras, global radio, real-time weather, and more. There’s something for everyone to explore on Earf."
        />
      </Helmet>

      {isLoading ? (
        <div className="full-container">
          <div className="spinner-wrapper">
            <div className="spinner-content">
              <img
                className="image-spinner"
                src="/metaimage.png"
                alt="Loading"
              />
              <div className="browse-loading-text">
                <h4 className="welcome-text">Welcome to</h4>
                <h5 className="earf-text1">EARF</h5>
              </div>
            </div>
          </div>

          {/* Loader Footer */}
          <footer className="loader-footer">
            <div className="footer-content">
              <p>
                Is your stream on <span className="earf-highlight">EARF</span>?
                Want to get verified?{" "}
                <img
                  src="/partner.png"
                  alt="Verified Partner"
                  aria-label="Verified Partner"
                  className="tick-icon"
                />{" "}
                <Link to="/how-to-get-verified" className="loading-footer-link">
                  {" "}
                  Learn How
                </Link>
              </p>
            </div>
          </footer>
        </div>
      ) : (
        <>
          <VideoSection videoData={videoData} />
          <FeatureRow />
          <div className="spacer-line1"></div>
          <CountryRow />
          <div className="spacer-line1"></div>
          <CategoryRow />
          <div className="spacer-line1"></div>
          <Footer />
        </>
      )}
    </div>
  );
};
export default Home;
