import React, { useState, useEffect } from "react";
import styles from "./LocationInfo.module.css";
import { useLocation } from "react-router-dom";

// Helper function to fetch the time from the backend
const fetchTime = async (latitude, longitude) => {
  try {
    const response = await fetch("https://server.earf.tv/location/time", {
      method: "POST",
      headers: {
        "x-api-key": process.env.REACT_APP_EARF_API_KEY,
        "Content-Type": "application/json",
        "X-Request-Source": "Stream-Location-Time",
      },
      body: JSON.stringify({ lat: latitude, lon: longitude }), // Send coordinates in the body
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to fetch time");
    }

    const data = await response.json();
    return data.formattedTime; // Returns the formatted time from the backend
  } catch (error) {
    console.error("Error fetching time from backend:", error.message);
    return null;
  }
};

// Helper function to fetch weather from the backend
const fetchWeather = async (latitude, longitude) => {
  try {
    const response = await fetch("https://server.earf.tv/location/weather", {
      method: "POST",
      headers: {
        "x-api-key": process.env.REACT_APP_EARF_API_KEY,
        "Content-Type": "application/json",
        "X-Request-Source": "Stream-Location-Weather",
      },
      body: JSON.stringify({ lat: latitude, lon: longitude }), // Send coordinates in the body
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to fetch weather");
    }

    const data = await response.json();
    return data; // Expecting weather object with temperature, description, and icon
  } catch (error) {
    console.error("Error fetching weather from backend:", error.message);
    return null;
  }
};

// Helper function to capitalize the first letter of each word in the description
const capitalizeDescription = (description) => {
  return description
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const LocationInfo = ({ coordinates }) => {
  const [localTime, setLocalTime] = useState(null);
  const [weather, setWeather] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Ensure that we have valid coordinates
    if (!coordinates || coordinates.length !== 2) return;

    const getLocationData = async () => {
      const [longitude, latitude] = coordinates;

      // Fetch initial time and weather
      const timeData = await fetchTime(latitude, longitude);
      if (timeData) {
        setLocalTime(new Date(timeData));
      }

      const weatherData = await fetchWeather(latitude, longitude);
      setWeather(weatherData);
    };

    getLocationData();
  }, [coordinates, location.state]);

  // This useEffect runs every second to update the local time like a clock
  useEffect(() => {
    if (localTime) {
      const interval = setInterval(() => {
        setLocalTime((prevTime) => new Date(prevTime.getTime() + 1000));
      }, 1000);

      return () => clearInterval(interval); // Clear the interval on component unmount
    }
  }, [localTime]);

  // Handle loading state
  if (!localTime || !weather) {
    return (
      <div className={styles.centeredcontainer}>
        <div className={styles.skeletonloader}>
          <div className={styles.skeletonTime}></div>
          <div className={styles.skeletonWeather}></div>
        </div>
      </div>
    );
  }

  // Convert Celsius to Fahrenheit
  const tempCelsius = Math.round(weather.temperature);
  const tempFahrenheit = Math.round((tempCelsius * 9) / 5 + 32);

  return (
    <div className={styles["location-info-container"]}>
      <div className={styles["location-info"]}>
        <div className={styles["location-time"]}>
          <p>{localTime.toLocaleTimeString()}</p>
        </div>
        {weather && weather.description ? (
          <div className={styles["location-weather"]}>
            <div className={styles["weather-info"]}>
              <img
                src={weather.icon} // Use the icon URL returned by the backend
                alt={capitalizeDescription(weather.description)}
              />
              <div className={styles["weather-details"]}>
                <p>
                  {tempCelsius}°C / {tempFahrenheit}°F -{" "}
                  {capitalizeDescription(weather.description)}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles["location-weather"]}>
            <p>Weather data not available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationInfo;
