import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import FeatureTags from './FeatureTags/FeatureTags';
import KnownAsTags from './KnownAsTags/KnownAsTags';
import styles from './StreamDescription.module.css';

const imageIcons = {
  officialWebsite: '/Stream-Assets/website.png',
  tripadvisor: '/Stream-Assets/tripadvisor.png',
  wikipedia: '/Stream-Assets/wikipedia.png',
  facebook: '/Stream-Assets/facebook.png',
  instagram: '/Stream-Assets/instagram.png',
  twitter: '/Stream-Assets/twitter.png',
  youtube: '/Stream-Assets/youtube.png',
  google: '/Stream-Assets/google.png',
};

const linkTooltips = {
  officialWebsite: 'Visit Official Website',
  google: 'Search on Google',
  tripadvisor: 'Explore Travel on Tripadvisor',
  wikipedia: 'Learn on Wikipedia',
  facebook: 'View Facebook',
  instagram: 'View Instagram',
  twitter: 'View Twitter (X)',
  youtube: 'View YouTube',
};

const StreamDescription = ({ qid, countryCode }) => {
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWikidataDetails = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(
          `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${qid}&props=labels|descriptions|sitelinks|claims&format=json&origin=*`
        );
        const data = await response.json();

        if (data.entities && data.entities[qid]) {
          const entity = data.entities[qid];
          const tempName = entity.labels?.en?.value || 'Unknown Name';
          setName(tempName);

          // Extract description
          const wikipediaTitle = entity.sitelinks?.enwiki?.title;
          if (wikipediaTitle) {
            const wikipediaDescription = await fetchWikipediaDescription(wikipediaTitle);
            setDescription(wikipediaDescription || entity.descriptions?.en?.value || '');
          } else {
            setDescription(entity.descriptions?.en?.value || 'No description available.');
          }

          // Extract links
          const tempLinks = [];
          if (entity.claims) {
            if (entity.claims.P856) {
              tempLinks.push({
                type: 'officialWebsite',
                url: entity.claims.P856[0].mainsnak.datavalue.value,
              });
            }
            if (entity.claims.P3134) {
              const tripAdvisorId = entity.claims.P3134[0].mainsnak.datavalue.value;
              tempLinks.push({
                type: 'tripadvisor',
                url: `https://www.tripadvisor.com/Attraction_Review-g${tripAdvisorId}`,
              });
            }
            
            if (entity.claims.P2002) {
              tempLinks.push({
                type: 'twitter',
                url: `https://twitter.com/${entity.claims.P2002[0].mainsnak.datavalue.value}`,
              });
            }
            if (entity.claims.P2003) {
              tempLinks.push({
                type: 'instagram',
                url: `https://instagram.com/${entity.claims.P2003[0].mainsnak.datavalue.value}`,
              });
            }
            if (entity.claims.P855) {
              tempLinks.push({
                type: 'youtube',
                url: entity.claims.P855[0].mainsnak.datavalue.value,
              });
            }
          }

          // Add Google Search link
          if (tempName) {
            tempLinks.push({
              type: 'google',
              url: `https://www.google.com/search?q=${encodeURIComponent(tempName)}`,
            });
          }

          // Sort links by order: Website > Google > TripAdvisor > Wikipedia > Socials
          const linkOrder = [
            'officialWebsite',
            'google',
            'tripadvisor',
            'wikipedia',
            'facebook',
            'instagram',
            'twitter',
            'youtube',
          ];

          const sortedLinks = tempLinks.sort(
            (a, b) => linkOrder.indexOf(a.type) - linkOrder.indexOf(b.type)
          );

          setLinks(sortedLinks);
        } else {
          setName('Unknown Name');
          setDescription('No description available.');
        }
      } catch (error) {
        console.error('Error fetching data from Wikidata:', error);
        setName('Error');
        setDescription('');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchWikipediaDescription = async (title) => {
      try {
        const response = await fetch(
          `https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(title)}`
        );
        const data = await response.json();
        return data.extract || '';
      } catch (error) {
        console.error('Error fetching Wikipedia description:', error);
        return '';
      }
    };

    if (qid) {
      fetchWikidataDetails();
    }
  }, [qid]);

  return (
    <>
      {isLoading ? (
        <div className={styles.skeletonloader}>
          <div className={styles.skeletonHeader}></div>
          <div className={styles.skeletonText}></div>
          <div className={styles.skeletonText}></div>
          <div className={styles.skeletonText}></div>
        </div>
      ) : (
        description && (
          <div className={styles.description}>
            <div className={styles['content-container']}>
              <div>
                <div className={styles['about-container']}>
                  <div className={styles['header-container']}>
                    <h2 className={styles['about-header']}>
                      About{' '}
                      <span className={styles['highlight-blue']}>
                        {name}{' '}
                        {countryCode && (
                          <img
                            src={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`}
                            alt={`${countryCode} flag`}
                            className={styles.flag}
                          />
                        )}
                      </span>
                    </h2>
                    <div className={styles.linksContainer}>
                      {links.map((link) => (
                        <a
                          key={link.url}
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.link}
                          title={linkTooltips[link.type]}
                        >
                          <img
                            src={imageIcons[link.type]}
                            alt={`${link.type} icon`}
                            className={styles.icon}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                  <KnownAsTags qid={qid} className={styles['known-as-tags']} />
                </div>

                <p className={`${styles.aboutdescription} ${styles['margin-top-10']}`}>{description}</p>
                {qid && <FeatureTags qid={qid} className="feature-tags-mobile" />}

                <div className={styles.descriptionnotice}>
                  Information may not always be accurate.
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default StreamDescription;
