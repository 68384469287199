import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to render fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to console or an error reporting service
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  handleRetry = () => {
    this.setState({ hasError: false });
    // Optionally refresh the page or retry fetching data
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Render a styled fallback UI
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#1e1e1e',
            color: '#f1f1f1',
            textAlign: 'center',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <div>
            <h1
              style={{
                fontSize: '4rem',
                margin: '0 0 20px 0',
              }}
            >
              😢
            </h1>
            <p
              style={{
                fontSize: '1.5rem',
                margin: '0 0 20px 0',
              }}
            >
            Earf.TV is currently experiencing technical difficulties.
            </p>
            <button
              onClick={this.handleRetry}
              style={{
                padding: '10px 20px',
                fontSize: '1rem',
                color: '#1e1e1e',
                backgroundColor: '#f1f1f1',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                transition: 'opacity 0.3s ease',
              }}
              onMouseOver={(e) => (e.target.style.opacity = '0.8')}
              onMouseOut={(e) => (e.target.style.opacity = '1')}
            >
              Refresh Page
            </button>
          </div>
        </div>
      );
    }

    // If no error, render the children
    return this.props.children;
  }
}

export default ErrorBoundary;
