import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./VideoSection.module.css";
import PlaceInfoBar from "./PlaceInfoBar";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const VideoSection = ({ videoData }) => {
  const navigate = useNavigate();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoDetailsCache, setVideoDetailsCache] = useState({});
  const [userInteracted, setUserInteracted] = useState(false);
  const sliderRef = useRef(null);
  const [loadedImages, setLoadedImages] = useState({});
  const [iframeLoaded, setIframeLoaded] = useState(false);

  // Timer to change the video automatically
  useEffect(() => {
    if (!userInteracted) {
      const interval = setInterval(() => {
        const currentIndex = videoData.findIndex(
          (video) => video.id === selectedVideo?.id
        );
        const nextIndex = (currentIndex + 1) % videoData.length;
        const nextVideo = videoData[nextIndex];
        setSelectedVideo(nextVideo);
        sliderRef.current?.slickGoTo(nextIndex); // Sync carousel with the selected video
      }, 15000);

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [userInteracted, selectedVideo, videoData]);

  // Custom Previous Arrow
  const CustomPrevArrow = ({ onClick }) => (
    <button className={`${styles.arrow} ${styles.prevArrow}`} onClick={onClick}>
      <FaChevronLeft />
    </button>
  );

  // Custom Next Arrow
  const CustomNextArrow = ({ onClick }) => (
    <button className={`${styles.arrow} ${styles.nextArrow}`} onClick={onClick}>
      <FaChevronRight />
    </button>
  );

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    focusOnSelect: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    afterChange: (index) => {
      const newSelectedVideo = videoData[index];
      setSelectedVideo(newSelectedVideo);
      handleFetchVideoDetails(newSelectedVideo.youtubeId); // Fetch details lazily
    },
  };

  const fetchVideoDetails = async (videoId) => {
    try {
      const response = await fetch(
        `https://server.earf.tv/live-cameras/video-details/${videoId}`,
        {
          method: "POST",
          headers: {
            "x-api-key": process.env.REACT_APP_EARF_API_KEY,
            "Content-Type": "application/json",
            "X-Request-Source": "Video-Section-Details",
          },
        }
      );

      if (!response.ok) {
        console.error(`Failed to fetch video details for ID: ${videoId}`);
        return null;
      }

      const data = await response.json();
      return {
        channelName: data.channel.name,
        channelThumbnail: data.channel.thumbnail,
        channelUrl: data.channel.url,
        liveViewers: data.liveViewers,
        manualdescription: data.manualdescription || null, // Include manualdescription
      };
    } catch (error) {
      console.error(`Error fetching video details for ID: ${videoId}`, error);
      return null;
    }
  };

  const handleFetchVideoDetails = async (videoId) => {
    if (videoDetailsCache[videoId]) {
      // If details are already cached, no need to fetch
      return;
    }

    const details = await fetchVideoDetails(videoId);

    if (details) {
      setVideoDetailsCache((prevCache) => ({
        ...prevCache,
        [videoId]: details,
      }));
    }
  };

  useEffect(() => {
    // Select a random video initially
    const randomIndex = Math.floor(Math.random() * videoData.length);
    const initialVideo = videoData[randomIndex];
    setSelectedVideo(initialVideo);
    handleFetchVideoDetails(initialVideo.youtubeId);

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(randomIndex);
    }
  }, [videoData]);

  const handleUserInteraction = () => {
    setUserInteracted(true);
  };

  const handleTagClick = (tag, index) => {
    if (index < 2) {
      // Only make tag1 and tag2 clickable
      navigate(`/browse/${tag}`);
    }
  };

  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [index]: true,
    }));
  };

  const channelData = selectedVideo
    ? videoDetailsCache[selectedVideo.youtubeId] || {
        channelName: "",
        channelThumbnail: "",
        channelUrl: "",
        liveViewers: 0,
        manualdescription: null,
      }
    : {};

  return (
    <div className={styles["video-section-container"]}>
      <div className={styles["video-section-left"]}>
        <h2 className={styles["video-section-title"]}>
          {selectedVideo?.title ? (
            selectedVideo.title
          ) : (
            <span
              className={`${styles.skeleton} ${styles["video-section-title"]}`}
              style={{ width: "70%" }}
            >
              &nbsp;
            </span>
          )}
        </h2>

        <div className={styles["video-section-channel-info"]}>
          <a
            href={channelData.channelUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {channelData.channelThumbnail ? (
              <img
                src={channelData.channelThumbnail}
                alt={channelData.channelName}
                className={styles["video-section-channel-thumbnail"]}
              />
            ) : (
              <div
                className={`${styles.skeleton} ${styles["video-section-channel-thumbnail"]}`}
                style={{ width: "50px", height: "50px" }}
              ></div>
            )}
          </a>
          <div className={styles["video-section-channel-details"]}>
            <a
              href={channelData.channelUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles["video-section-channel-name"]}
            >
              {channelData.channelName ? (
                channelData.channelName
              ) : (
                <span
                  className={styles.skeleton}
                  style={{ width: "120px", display: "inline-block" }}
                >
                  &nbsp;
                </span>
              )}
              {selectedVideo?.partner === "YES" && (
                <img
                  src="/partner.png"
                  alt="Partner Icon"
                  className={styles["partner-icon"]}
                />
              )}
            </a>
            <p className={styles["video-section-wikidata"]}>
              {selectedVideo?.wikidata ? (
                selectedVideo.wikidata
              ) : (
                <span
                  className={styles.skeleton}
                  style={{ width: "80px", display: "inline-block" }}
                >
                  &nbsp;
                </span>
              )}
            </p>
            <p className={styles["video-section-view-count"]}>
              {channelData.liveViewers > 0
                ? `${channelData.liveViewers} watching`
                : `0 watching`}
            </p>
          </div>
        </div>

        <div className={styles["video-section-tags"]}>
          {selectedVideo?.tags && selectedVideo.tags.length > 0 ? (
            selectedVideo.tags.map((tag, index) => (
              <span
                key={index}
                className={styles["video-section-tag"]}
                onClick={() => handleTagClick(tag, index)}
                style={{ cursor: index < 2 ? "pointer" : "default" }} // Only show pointer cursor for tag1 and tag2
              >
                {tag}
              </span>
            ))
          ) : (
            <>
              <span
                className={`${styles.skeleton} ${styles["video-section-tag"]}`}
                style={{ width: "50px" }}
              >
                &nbsp;
              </span>
              <span
                className={`${styles.skeleton} ${styles["video-section-tag"]}`}
                style={{ width: "70px" }}
              >
                &nbsp;
              </span>
            </>
          )}
        </div>

        {selectedVideo?.wikidata && (
          <PlaceInfoBar wikidata={selectedVideo.wikidata} />
        )}

        <div className={styles["video-section-description"]}>
          <p
            dangerouslySetInnerHTML={{
              __html:
                channelData.manualdescription || selectedVideo?.description,
            }}
          ></p>
        </div>

        <Slider
          {...sliderSettings}
          className={styles["video-section-carousel"]}
          ref={sliderRef}
        >
          {videoData.length > 0
            ? videoData.map((video, index) => (
                <div
                  key={video.id}
                  className={`${styles["video-section-thumbnail-item"]} ${
                    selectedVideo?.id === video.id ? styles["active"] : ""
                  }`}
                  onClick={() => {
                    setSelectedVideo(video);
                    handleUserInteraction();
                  }}
                >
                  {/* Actual Thumbnail (only show when loaded) */}
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    className={`${styles["video-section-thumbnail-image"]} ${
                      loadedImages[index] ? styles["loaded"] : styles["loading"]
                    }`}
                    loading="lazy"
                    onLoad={() => handleImageLoad(index)}
                  />

                  {/* Skeleton Thumbnail (only shows when not loaded) */}
                  {!loadedImages[index] && (
                    <div className={styles["skeleton-thumbnail"]}></div>
                  )}

                  {/* Video Title */}
                  <p className={styles["video-section-thumbnail-title"]}>
                    {video.title}
                  </p>

                  {/* Skeleton Title (only shows when title is missing) */}
                  {!video.title && (
                    <div className={styles["skeleton-title"]}></div>
                  )}

                  {/* Wikidata Info */}
                  <p className={styles["video-section-thumbnail-wikidata"]}>
                    {video.wikidata}
                  </p>
                </div>
              ))
            : // Show skeletons while data is loading
              [...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className={styles["video-section-thumbnail-item"]}
                >
                  <div className={styles["skeleton-thumbnail"]}></div>
                  <div className={styles["skeleton-title"]}></div>
                </div>
              ))}
        </Slider>
      </div>

      <div className={styles["video-section-right"]}>
        <div className={styles["video-section-video-wrapper"]}>
          {/* Skeleton Loader (Only Shows When Iframe is Loading) */}
          {!iframeLoaded && (
            <div className={styles["iframe-placeholder"]}></div>
          )}

          {/* YouTube Iframe */}
          <iframe
            src={`https://www.youtube.com/embed/${selectedVideo?.youtubeId}?autoplay=1&mute=1`}
            title={selectedVideo?.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={`${
              iframeLoaded ? styles["loaded-iframe"] : styles["loading-iframe"]
            }`}
            onLoad={() => setIframeLoaded(true)} // Hides Skeleton when Loaded
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
