import { useState, useEffect } from "react";
import styles from "./ConsentPopup.module.css"; // Import as module

const ConsentPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [region, setRegion] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("userConsent")) return; // Don't show if already accepted/rejected

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (isCalifornia(timezone)) {
      setRegion("California");
      setShowPopup(true);
    } else if (isBrazil(timezone)) {
      setRegion("Brazil");
      setShowPopup(true);
    } else if (isEUCountry(timezone)) {
      setRegion("EU");
      setShowPopup(true);
    }
  }, []);

  const acceptConsent = () => {
    localStorage.setItem("userConsent", "accepted");
    setShowPopup(false);
  };

  const declineConsent = () => {
    setShowPopup(false);
    setShowConfirmPopup(true); // Show confirmation popup instead
  };

  const confirmLeave = () => {
    localStorage.setItem("userConsent", "declined");
    window.location.href = "https://www.google.com"; // Redirect user away
  };

  const confirmStay = () => {
    localStorage.setItem("userConsent", "declined");
    setShowConfirmPopup(false); // Close popup, continue using Earf.TV
  };

  return (
    <>
      {/* Main Consent Popup */}
      {showPopup && (
        <div className={styles.consentPopup}>
          <div className={styles.consentContent}>
            <p>
              <strong className={styles.earfBlue}>EARF</strong> does not store
              personal user data, but to comply with{" "}
              <strong>regional privacy laws</strong>, we provide this notice. We
              use <strong>local storage</strong> only for site preferences and
              compliance pop-ups.
            </p>

            {region === "California" && (
              <p>
                Under the{" "}
                <strong>California Consumer Privacy Act (CCPA)</strong>,
                California residents have the right to opt out of certain data
                tracking.
                <br />
                See our <a href="/disclaimer">Disclaimer</a>,
                <a href="/privacy-policy"> Privacy Policy</a>, and
                <a href="/terms-of-service"> Terms of Service</a>.
              </p>
            )}

            {region === "Brazil" && (
              <p>
                Under <strong>Lei Geral de Proteção de Dados (LGPD)</strong>,
                Brazilian users have the right to transparency in data usage.
                <br />
                See our <a href="/disclaimer">Disclaimer</a>,
                <a href="/privacy-policy"> Privacy Policy</a>, and
                <a href="/terms-of-service"> Terms of Service</a>.
              </p>
            )}

            {region === "EU" && (
              <p>
                Under the{" "}
                <strong>General Data Protection Regulation (GDPR)</strong>,
                users in the EU must be informed about any local storage usage.
                <br />
                See our <a href="/disclaimer">Disclaimer</a>,
                <a href="/privacy-policy"> Privacy Policy</a>, and
                <a href="/terms-of-service"> Terms of Service</a>.
              </p>
            )}

            <div className={styles.consentButtons}>
              <button onClick={acceptConsent} className={styles.acceptBtn}>
                Accept
              </button>
              <button onClick={declineConsent} className={styles.declineBtn}>
                Decline
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Second Confirmation Popup (For Declining Consent) */}
      {showConfirmPopup && (
        <div className={styles.confirmPopup}>
          <div className={styles.confirmContent}>
            <h3>You have chosen to decline consent. Would you like to:</h3>
            <ul>
              <p>
                <strong>Leave the site</strong> and be redirected to{" "}
                <strong>Google?</strong>
              </p>
              <p>
                <strong>OR</strong>
              </p>
              <p>
                <strong>
                  Continue using
                  <strong style={{ color: "var(--earf-blue)" }}> EARF</strong>
                </strong>{" "}
                with consent declined.
                <strong style={{ color: "var(--earf-blue)" }}>
                  {" "}
                  EARF
                </strong>{" "}
                will not be held responsible under any regional privacy laws, as
                you have voluntarily chosen to continue with consent declined.
              </p>
            </ul>
            <div className={styles.confirmButtons}>
              <button onClick={confirmLeave} className={styles.leaveBtn}>
                Leave Site
              </button>
              <button onClick={confirmStay} className={styles.stayBtn}>
                Stay & Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// ✅ Check if the user is from California
const isCalifornia = (timezone) => timezone.includes("America/Los_Angeles");

// ✅ Check if the user is from Brazil
const isBrazil = (timezone) => {
  const brazilTimezones = [
    "America/Sao_Paulo",
    "America/Fortaleza",
    "America/Recife",
    "America/Belem",
    "America/Manaus",
    "America/Boa_Vista",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Porto_Velho",
    "America/Rio_Branco",
  ];
  return brazilTimezones.includes(timezone);
};

// ✅ Check if the user is from the EU
const isEUCountry = (timezone) => {
  const EU_TIMEZONES = [
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
  ];
  return EU_TIMEZONES.includes(timezone);
};

export default ConsentPopup;
