import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MapboxMap = ({ longitude, latitude, setLongitude, setLatitude }) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const defaultCenter = [-99.1332, 38.9072]; // Default center over the USA


  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    // Determine initial zoom
    const initialZoom = longitude && latitude ? 15 : 3;

    const map = new mapboxgl.Map({
      container: mapRef.current,
      style: 'mapbox://styles/mapbox/standard',
      center: longitude && latitude ? [longitude, latitude] : defaultCenter,
      zoom: initialZoom,
    });

    // Add marker if coordinates exist
    if (longitude && latitude) {
      markerRef.current = new mapboxgl.Marker({ color: 'red' })
        .setLngLat([longitude, latitude])
        .addTo(map);
    }

    // Handle map click
    map.on('click', (event) => {
      const { lng, lat } = event.lngLat;

      setLongitude(lng.toFixed(6));
      setLatitude(lat.toFixed(6));

      // Move or create marker
      if (markerRef.current) {
        markerRef.current.setLngLat([lng, lat]);
      } else {
        markerRef.current = new mapboxgl.Marker({ color: 'red' })
          .setLngLat([lng, lat])
          .addTo(map);
      }

      // Smooth zoom into clicked location
      map.flyTo({ center: [lng, lat], zoom: 15, essential: true });
    });

    return () => map.remove(); // Cleanup
  }, [longitude, latitude]);

  return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
};

export default MapboxMap;
