import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styles from "./CountryDetails.module.css";

const CountryDetails = () => {
  const { countryName } = useParams(); // Get countryName from the URL
  const navigate = useNavigate(); // Get navigate function for navigation
  const [countryData, setCountryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountryDetails = async () => {
      setIsLoading(true);
      setError(null); // Reset any previous error

      try {
        const API_KEY = process.env.REACT_APP_EARF_API_KEY; // Assuming you have an API key

        const response = await fetch(
          `https://server.earf.tv/country-profiles/${countryName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": API_KEY, // Correct header name for the API key
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch country details");
        }

        const data = await response.json();
        setCountryData(data.properties); // The actual country data is in the 'properties' field
      } catch (error) {
        console.error("Error fetching country details:", error);
        setError("Country details not found or an error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountryDetails();
  }, [countryName]); // Re-run when the countryName changes

  if (isLoading)
    return (
      <div className={styles.loader}>
        <div className="full-container">
          <div className="spinner-wrapper">
            <div className="spinner-content">
              <img
                className="image-spinner"
                src="/metaimage.png"
                alt="Loading"
              />
              <div className="browse-loading-text">
                Learning about {countryName}...
              </div>
            </div>
          </div>
        </div>
      </div>
    ); // Display a loading indicator

  if (error) return <div className={styles.error}>{error}</div>;

  // Destructure the data, making sure to handle potential missing data
  const {
    name,
    iso_code,
    common_name,
    official_name,
    description,
    native_name,
    capital,
    region,
    subregion,
    population,
    area,
    landlocked,
    motto,
    timezones,
    dialing_root,
    dialing_suffixes,
    currencies,
    postal_format,
    emergency_numbers,
    links,
    flags_png,
    coat_of_arms_png,
    demonyms,
  } = countryData || {};

  // Extract native name based on the language (e.g., English)
  const nativeName = native_name ? native_name.eng?.common || "N/A" : "N/A";

  // Extract currency name and symbol
  const currency = currencies ? Object.values(currencies)[0]?.name : "N/A";
  const currencySymbol = currencies
    ? Object.values(currencies)[0]?.symbol
    : "N/A";

  // Safely access nested properties using optional chaining
  const { m: male, f: female } = demonyms || {};
  const flagUrl = `https://flagcdn.com/w320/${iso_code?.toLowerCase()}.png`;

  // Format emergency numbers
  const formattedEmergencyNumbers = emergency_numbers
    ? emergency_numbers.map((number) => {
        const [key, value] = number.replace(/["{}]/g, "").split(": ");
        return {
          key: key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase()),
          value,
        };
      })
    : [];

  return (
    <div className={styles.container}>
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>{name || "Country Details"} | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content={`Learn more about ${
            name || "this country"
          } on Earf. Explore details such as population, capital, region, and more.`}
        />

        {/* Canonical Tag */}
        <link rel="canonical" href={`https://earf.tv/country/${countryName}`} />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={`${name || "Country Details"} | Earf`}
        />
        <meta
          property="og:description"
          content={`Discover detailed information about ${
            name || "this country"
          } on Earf. Learn about its population, capital, region, and more.`}
        />
        <meta property="og:image" content={flags_png || "/Earf-E.png"} />
        <meta
          property="og:url"
          content={`https://earf.tv/country/${countryName}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`${name || "Country Details"} | Earf`}
        />
        <meta
          name="twitter:description"
          content={`Explore detailed information about ${
            name || "this country"
          } on Earf. Learn about its population, capital, region, and more.`}
        />
        <meta name="twitter:image" content={flags_png || "/Earf-E.png"} />
        <meta
          name="twitter:image:alt"
          content={`${name || "Country Details"} | Earf`}
        />
      </Helmet>

      <div className={styles.leftSection}>
        {iso_code && (
          <img src={flagUrl} alt={`${name} flag`} className={styles.flag} />
        )}
        {coat_of_arms_png && (
          <img
            src={coat_of_arms_png}
            alt={`${name} coat of arms`}
            className={styles.coatOfArms}
          />
        )}
      </div>
      <div className={styles.rightSection}>
        <section className={styles.section}>
          <h1 className={styles.earfBlue}>{name || "Unknown Country"}</h1>
          <p>
            <strong>Motto:</strong> {motto || "N/A"}
          </p>
          <h3>About</h3>
          <p>
            <strong>Common Name:</strong> {common_name || "N/A"}
          </p>
          <p>
            <strong>Official Name:</strong> {official_name || "N/A"}
          </p>
          <p>
            <strong>Native Name:</strong> {nativeName}
          </p>
          <p>
            <strong>Capital:</strong> {capital || "N/A"}
          </p>
          <p>
            <strong>Region:</strong> {region || "N/A"}
          </p>
          <p>
            <strong>Subregion:</strong> {subregion || "N/A"}
          </p>
          <p className={styles.description}>
            <strong>Description:</strong> {description || "N/A"}
          </p>
          <p>
            <strong>Population:</strong>{" "}
            {population ? population.toLocaleString() : "N/A"}
          </p>
          <p>
            <strong>Area:</strong> {area ? `${area} km²` : "N/A"}
          </p>
          <p>
            <strong>Landlocked:</strong> {landlocked ? "Yes" : "No"}
          </p>
        </section>

        <section className={styles.section}>
          <p>
            <strong>Timezones:</strong>{" "}
            {timezones ? timezones.join(", ") : "N/A"}
          </p>
          <p>
            <strong>Dialing Code:</strong>{" "}
            {dialing_root && dialing_suffixes
              ? `+${dialing_root} ${dialing_suffixes.join(", ")}`
              : "N/A"}
          </p>
          <p>
            <strong>Currencies:</strong> {currency} ({currencySymbol})
          </p>
          <p>
            <strong>Postal Format:</strong> {postal_format || "N/A"}
          </p>
        </section>

        <section className={styles.section}>
          <h2>Emergency Numbers</h2>
          {emergency_numbers ? (
            <ul>
              {emergency_numbers.map((number, index) => {
                const [key, value] = number.replace(/["{}]/g, "").split(": ");
                return (
                  <li key={index}>
                    <strong>
                      {key
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                      :
                    </strong>{" "}
                    {value}
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>N/A</p>
          )}
        </section>

        <section className={styles.section}>
          <h2>Links</h2>
          <ul>
            {links && links.length > 0 ? (
              Object.entries(links).map(([key, link], index) => (
                <li key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {key}
                  </a>
                </li>
              ))
            ) : (
              <li>No links available</li>
            )}
          </ul>
        </section>
        <section className={styles.section}>
          <p>
            <strong>Demonyms:</strong>
          </p>
          <p>
            <strong>Male:</strong> {male || "N/A"}
          </p>
          <p>
            <strong>Female:</strong> {female || "N/A"}
          </p>
        </section>
      </div>
    </div>
  );
};

export default CountryDetails;
