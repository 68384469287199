import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import styles from "./NotFound.module.css";

const NotFound = () => {
  const navigate = useNavigate();

  // Navigate to the home page when the button is clicked
  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <>
      {/* Helmet for Meta Information */}
      <Helmet>
        <title>Page Not Found | Earf</title>
        <meta
          name="description"
          content="Oops, something went wrong. We can't find the page you're looking for on Earf. Explore live streams, global radio, and more."
        />
        <meta property="og:title" content="404 - Page Not Found | Earf" />
        <meta property="fb:app_id" content="929645909242200" />

        <meta
          property="og:description"
          content="Oops, something went wrong. We can't find the page you're looking for on Earf. Explore live streams, global radio, and more."
        />
        <meta property="og:image" content="https://earf.tv/Earf-E.png" />
        <meta property="og:url" content="https://earf.tv/404" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="404 - Page Not Found | Earf" />
        <meta
          name="twitter:description"
          content="Oops, something went wrong. We can't find the page you're looking for on Earf."
        />
        <meta name="twitter:image" content="https://earf.tv/Earf-E.png" />
      </Helmet>

      <div className={styles["not-found-container"]}>
        <div className={styles["error-404"]}>
          <span className={styles["error-digit"]}>4</span>
          <img
            src="/metaimage.png"
            alt="Earf Logo"
            className={styles["earf-logo"]}
          />
          <span className={styles["error-digit"]}>4</span>
        </div>
        <h3 className={styles["error-message"]}>Oops, something went wrong</h3>
        <p className={styles["error-description"]}>
          We can't find the page you're looking for.
        </p>
        <button className={styles["go-home-button"]} onClick={handleGoHome}>
          Go Home
        </button>
      </div>
    </>
  );
};

export default NotFound;
