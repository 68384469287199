/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useNavigate } from "react-router-dom";
import "./PrivacyPolicy.css"; // CSS file for styling
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>Privacy Policy | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Discover how Earf collects, uses, and safeguards your personal information while you explore live-streams of Earth's beauty and real-time global data. Read our comprehensive Privacy Policy."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/privacy-policy" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Privacy Policy | Earf" />
        <meta
          property="og:description"
          content="Learn about Earf's commitment to protecting your privacy, including how we collect, use, and safeguard your personal data while you explore live-streams of Earth's beauty."
        />
        <meta property="og:image" content="https://earf.tv/Earf-E.png" />
        <meta property="og:url" content="https://earf.tv/privacy-policy" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="929645909242200" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Privacy Policy | Earf" />
        <meta
          name="twitter:description"
          content="Find out how Earf handles your personal information, ensuring your privacy is protected while using our live-streaming platform featuring Earth's beauty and real-time data."
        />
        <meta name="twitter:image" content="https://earf.tv/Earf-E.png" />
        <meta name="twitter:image:alt" content="Earf Privacy Policy" />
      </Helmet>

      {/* Header with Back Button (fixed at the top) */}
      <div className="header-container">
        <h1 className="privacy-header">
          <img src="/Earf-E.png" alt="Earf Logo" className="contact-logo" />{" "}
          Privacy Policy
        </h1>
      </div>

      {/* Scrollable content section */}
      <div className="privacy-content">
        <p style={{ fontSize: "16px" }}>
          <strong>Effective Date:</strong> March 21, 2025
        </p>

        {/* Privacy Policy */}
        <p>
          Earf, through its platform <strong>Earf.TV</strong>, previously known
          as <strong>Interactive.Earth</strong>, is committed to protecting your
          privacy. This Privacy Policy outlines how we collect, use, and
          safeguard your personal information when you interact with the
          Website.
        </p>

        <p>
          References to <strong>"we," "us,"</strong> and <strong>"our"</strong>{" "}
          refer to <strong>Earf</strong>, including but not limited to{" "}
          <strong>Earf.TV</strong>, <strong>Interactive.Earth</strong>, and all
          associated subdomains, websites, and services created and operated by
          Earf. This includes, but is not limited to,{" "}
          <strong>Remote Vehicle Estimating</strong>{" "}
          (remotevehicleestimating.com.au) and <strong>Encra</strong>{" "}
          (encra.me). By accessing or using any of these websites or services,
          you agree to be bound by our Privacy Policy.
        </p>

        <p>
          By accessing or using <strong>Earf</strong> (the "Website"), including
          any domains or subdomains associated with <strong>Earf</strong> and{" "}
          you confirm that you have read,
          understood, and agreed to this Privacy Policy. If you do not agree,
          please discontinue your use of the Website immediately.
        </p>
        <h3>Information We Collect</h3>
        <p>
          Earf does not store or collect personally identifiable user data.
          However, we use third-party services such as Google Analytics to
          understand platform usage and improve our services.
        </p>
        <p>
          Additionally, Earf detects a user's{" "}
          <strong>timezone on page load</strong> to determine eligibility for
          compliance popups related to{" "}
          <strong>GDPR (EU), CCPA (California), and LGPD (Brazil)</strong>. This
          is used to ensure that users receive the correct data protection
          disclosures based on their location.
        </p>
        <p>
          These analytics and compliance tracking mechanisms may collect{" "}
          <strong>non-personal</strong> data, including:
        </p>
        <ul>
          <li>Aggregated user behavior data (e.g., most viewed streams)</li>
          <li>Device type and browser information</li>
          <li>
            General location data inferred from timezone settings (e.g.,
            country-level analytics, not precise GPS data)
          </li>
        </ul>
        <p>
          Earf does not track, store, or retain individual user sessions, login
          details, or personally identifiable data. We do not require user
          accounts, and we do not share personal data with third parties.
        </p>

        <h3>How We Use Your Information</h3>
        <p>
          The only data collected by Earf is{" "}
          <strong>via third-party analytics services</strong>. We use this data
          to:
        </p>
        <ul>
          <li>Monitor platform performance and uptime</li>
          <li>Understand aggregated usage trends</li>
          <li>Improve the user experience based on anonymous data</li>
        </ul>
        <p>
          <strong>
            {" "}
            Earf does not collect, store, or track personal user information
            beyond analytics.
          </strong>{" "}
          If this policy ever changes, we will update our users in advance.
        </p>

        <h3>How We Protect Your Information</h3>
        <p>
          We employ robust SSL encryption and secure databases to ensure the
          safety and security of your data.
        </p>

        <h3>Cookies and Tracking Technologies</h3>
        <p>
          Earf uses <strong>local storage and session storage</strong> to
          enhance your experience by remembering certain preferences, such as:
        </p>
        <ul>
          <li>
            Whether you have accepted compliance popups (e.g., GDPR, CCPA, LGPD)
          </li>
          <li>Basic site preferences to improve usability</li>
        </ul>
        <p>
          We do <strong>NOT</strong> use cookies or local storage for tracking
          individual users, advertising, or storing personal information. You
          can manage your local storage and cookies through your browser
          settings.
        </p>

        <h3>Use of Device and Location Data</h3>
        <p>
          In cases where we collect, process, or disclose information that can
          pinpoint or reasonably infer an end user's precise geographic
          location, sourced from GPS, Wi-Fi, or cell tower data, we prioritize
          the utmost care and transparency in our practices:
        </p>
        <ul>
          <li>
            We explicitly notify users, through an interstitial or just-in-time
            notice, about the intended purposes for utilizing their data
            (including ad personalization, analytics, and attribution, as
            applicable), and clarify that the data will be shared with our
            partners, including Mapbox and Google, in an encrypted state.
          </li>
          <li>
            We obtain express, opt-in consent from end users before collecting,
            processing, or disclosing such information. This consent explicitly
            covers the transmission of data to Mapbox and Google in an encrypted
            format.
          </li>
          <li>
            It's important to note that once this information is transmitted to
            Mapbox and Google in an encrypted manner, our involvement ceases. We
            do not track, retain, or store this information on our servers or
            systems.
          </li>
          <li>
            Our commitment to user privacy extends to ensuring that the
            transmission of such information to Mapbox and Google occurs solely
            via secure encrypted channels, maintaining the encrypted state
            throughout the process and preventing any tracking or retention on
            our end.
          </li>
          <li>
            We provide comprehensive disclosure within all pertinent privacy
            policies, affirming that this information collection, processing, or
            disclosure does not involve tracking or retention on our end. Users
            can trust that their location data, once securely transmitted for
            specific purposes, is not stored or monitored by our systems.
          </li>
        </ul>

        <h3>Data Sharing and Disclosure</h3>
        <p>
          Earf does not sell, trade, or transfer personally identifiable user
          information to outside parties.
        </p>
        <p>
          However, we do work with trusted third-party services such as
          <strong> Google Analytics, YouTube API, and Google AdSense</strong> to
          enhance our platform. These services may process limited user data
          (such as browsing behavior or video interactions), but only in
          accordance with their own privacy policies.
        </p>
        <p>
          Any third-party services we use must comply with strict data security
          and confidentiality agreements to ensure user information remains
          protected.
        </p>

        <h3>Google Analytics</h3>
        <p>
          Earf utilizes Google Analytics, a web analytics service provided by
          Google, Inc. Google Analytics uses cookies to help analyze how users
          interact with our website. The information generated by the cookie
          about your use of Earf.TV or Interactive.Earth (including your IP
          address) will be transmitted to and stored by Google on servers in the
          United States.
        </p>
        <p>
          This information is used to evaluate visitors' use of the website,
          compile reports on website activity, and provide other services
          related to website activity and internet usage. Google may also
          transfer this information to third parties where required to do so by
          law or where such third parties process the information on Google's
          behalf.
        </p>
        <p>
          By using Earf.TV or Interactive.Earth, you consent to the processing
          of data about you by Google in the manner and for the purposes set out
          above.
        </p>
        <p>
          For more information on how Google Analytics collects and processes
          data, as well as how you can control the information sent to Google,
          please review Google's Privacy Policy for Partner Sites.
        </p>

        <h3>YouTube API</h3>
        <p>
          Earf utilizes the YouTube API to provide video content and related
          services on our platforms. By using our services, you agree to be
          bound by the YouTube Terms of Service. The YouTube API collects and
          processes the following data:
        </p>
        <ul>
          <li>YouTube video IDs and metadata</li>
          <li>
            User interactions with embedded YouTube videos (e.g., play, pause,
            like, etc.)
          </li>
          <li>Video likes</li>
          <li>Live viewers</li>
          <li>Thumbnails</li>
          <li>
            IP address and device information when accessing YouTube content
          </li>
        </ul>
        <p>We use this information to:</p>
        <ul>
          <li>
            Provide and enhance video content and services on our platforms
          </li>
          <li>
            Analyze user interactions with YouTube videos to improve our
            offerings
          </li>
        </ul>
        <p>
          We adhere to the YouTube API Services Terms of Service and Google's
          Privacy Policy. For more information on how YouTube collects and
          processes data, please visit the{" "}
          <a href="https://policies.google.com/privacy" target="_blank">
            Google Privacy Policy
          </a>
          .
        </p>
        <p>
          If you wish to revoke access to your data via the YouTube API, you can
          do so by visiting the{" "}
          <a
            href="https://security.google.com/settings/security/permissions"
            target="_blank"
          >
            Google security settings page
          </a>
          .
        </p>

        <h3>Google AdSense</h3>
        <p>
          Earf may use Google AdSense to display advertisements on our
          platforms. Google AdSense may use cookies to personalize the content
          and ads you see, provide social media features, and analyze our
          traffic. Google may also share information about your use of our
          platforms with social media, advertising, and analytics partners.
        </p>

        <h3>Discord</h3>
        <p>
          Earf operates an official Discord server for community discussions,
          support, and engagement. By joining and participating in the Earf
          Discord server, you acknowledge and agree to the following:
        </p>

        <h4>Data Collection & Usage</h4>
        <ul>
          <li>
            We do <strong>not</strong> store or collect personal data from
            Discord outside of what is
            <strong> publicly visible</strong> within the server.
          </li>
          <li>
            Discord itself may collect data related to your usage, in accordance
            with its own
            <a href="https://discord.com/privacy" target="_blank">
              {" "}
              Privacy Policy
            </a>
            .
          </li>
          <li>
            Messages, interactions, or reports related to moderation may be
            <strong> temporarily logged</strong> for enforcement purposes.
          </li>
        </ul>

        <h4>Third-Party Platform Disclaimer</h4>
        <p>
          Discord is a third-party platform, and by using it, you are also
          subject to its
          <a href="https://discord.com/terms" target="_blank">
            {" "}
            Terms of Service{" "}
          </a>{" "}
          and
          <a href="https://discord.com/guidelines" target="_blank">
            {" "}
            Community Guidelines
          </a>
          .
        </p>
        <p>
          Earf is <strong> not responsible</strong> for Discord's data
          practices, and any concerns regarding privacy should be directed to
          Discord’s support.
        </p>

        <h4>Moderation & Enforcement</h4>
        <ul>
          <li>
            We reserve the right to <strong> restrict or remove</strong> access
            to the Discord server for violations of Earf’s guidelines or
            Discord’s policies.
          </li>
          <li>
            User reports and moderation actions may be reviewed internally to
            maintain a<strong> safe and respectful environment</strong>.
          </li>
          <li>
            If you believe your moderation action was in error, you may contact
            <a href="mailto:legal@earf.tv"> legal@earf.tv</a> for review.
          </li>
        </ul>

        <h3>User Rights</h3>
        <p>
          You have specific rights regarding your personal data, even though
          Earf does not collect or store personal user information beyond basic
          analytics. If any data collection policies change, users will be
          informed in advance.
        </p>

        <h3>CPRA Compliance (California Privacy Rights Act)</h3>
        <p>
          The <strong>California Privacy Rights Act (CPRA)</strong> expands on
          the previous CCPA (California Consumer Privacy Act) by granting
          residents additional rights over their personal data. Although Earf
          does not sell personal data, we respect the privacy rights of
          California users.
        </p>
        <ul>
          <li>
            <strong>Right to Opt-Out:</strong> California residents have the
            right to opt out of the sale or sharing of their personal data.
            However, Earf does not and will not sell personal data to third
            parties.
          </li>
          <li>
            <strong>Right to Access and Delete:</strong> Users may request to
            access any personal data collected and request its deletion, except
            in cases where retention is required by law.
          </li>
          <li>
            <strong>Right to Know and Correct:</strong> Users have the right to
            know what personal data is collected, why it is collected, and the
            right to request corrections to inaccurate data.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> If personal data is
            collected in the future, users will have the right to request their
            data in a structured, machine-readable format for transfer.
          </li>
          <li>
            <strong>Right to Restrict Processing:</strong> Users may limit how
            their data is used, particularly for targeted advertising or
            third-party analytics.
          </li>
          <li>
            <strong>Non-Discrimination:</strong> Earf will never deny services
            or privileges to users who exercise their privacy rights.
          </li>
        </ul>
        <p>
          California residents wishing to exercise these rights may contact us
          at
          <a href="mailto:legal@earf.tv"> legal@earf.tv</a>.
        </p>

        <h3>GDPR Compliance (General Data Protection Regulation - EU)</h3>
        <p>
          The <strong>General Data Protection Regulation (GDPR)</strong> applies
          to users within the European Union (EU) and provides strict
          protections for personal data. Earf ensures compliance with GDPR
          principles.
        </p>
        <ul>
          <li>
            <strong>Purpose and Legal Basis for Data Processing:</strong> Any
            data collected is processed based on user consent, contractual
            necessity, or legitimate interest, such as analytics.
          </li>
          <li>
            <strong>Data Subject Rights:</strong> EU users have the right to
            access, correct, delete, or restrict the processing of their
            personal data.
          </li>
          <li>
            <strong>Right to Erasure ("Right to be Forgotten"):</strong> Users
            can request the deletion of personal data, unless retention is
            legally required.
          </li>
          <li>
            <strong>Right to Object:</strong> Users may object to their data
            being used for specific purposes, including marketing or analytics.
          </li>
          <li>
            <strong>Data Retention:</strong> We only retain data for as long as
            necessary to fulfill the purposes outlined in this policy, unless
            required by law.
          </li>
          <li>
            <strong>Data Transfers Outside the EU:</strong> If data is
            transferred outside the EU, Earf ensures that appropriate
            safeguards, such as Standard Contractual Clauses (SCCs), are in
            place.
          </li>
        </ul>
        <p>
          EU users can contact <a href="mailto:legal@earf.tv"> legal@earf.tv</a>{" "}
          to exercise their GDPR rights.
        </p>

        <h3>LGPD Compliance (Lei Geral de Proteção de Dados - Brazil)</h3>
        <p>
          The <strong>Lei Geral de Proteção de Dados (LGPD)</strong> is Brazil’s
          data protection law, ensuring that personal data is handled
          transparently and securely. Earf complies with LGPD regulations.
        </p>
        <ul>
          <li>
            <strong>Purpose and Legal Basis for Data Processing:</strong> Any
            collected data is processed in accordance with user consent,
            legitimate interest, or contractual necessity.
          </li>
          <li>
            <strong>Data Subject Rights:</strong> Brazilian users have the right
            to access, correct, delete, or anonymize their personal data.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> Users can request a copy
            of their data in a portable format.
          </li>
          <li>
            <strong>Right to Revoke Consent:</strong> Users may withdraw their
            consent to data processing at any time.
          </li>
          <li>
            <strong>Data Transfers:</strong> When transferring data outside
            Brazil, Earf ensures that proper safeguards are in place to protect
            user data.
          </li>
        </ul>
        <p>
          Brazilian users can exercise their rights by contacting
          <a href="mailto:legal@earf.tv"> legal@earf.tv</a>.
        </p>

        <h3>International Data Transfers</h3>
        <p>
          In any case where we transfer data internationally, we implement
          appropriate safeguards to protect your data, such as standard
          contractual clauses approved by regulatory bodies.
        </p>

        <h3>Opt-Out Options</h3>
        <p>
          You can opt out of data collection or targeted advertising by
          adjusting your browser settings or preferences. For targeted
          advertising, you can also opt out through the{" "}
          <a href="https://optout.networkadvertising.org/?c=1" target="_blank">
            NAI Opt-Out page
          </a>
          .
        </p>

        <h3>Policy Modification</h3>
        <p>
          This policy may be revised periodically. Users will be notified of any
          alterations through an updated effective date at the top of this page.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any inquiries regarding this Privacy Policy, kindly reach
          out to us at <a href="mailto:legal@earf.tv"> legal@earf.tv</a>, or
          visit our Help and Contact page for further assistance.
        </p>
        <p className="footer-text">
          © {new Date().getFullYear()} Earf.TV. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
