/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  Link,
} from "react-router-dom";
import ReactGA from "react-ga4";
import { PlayerProvider } from "./components/context/PlayerContext";
import { AdminProvider, AdminContext } from "./components/AdminContext";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";
import MobileSidebar from "./components/Sidebar/MobileSidebar/MobileSidebar";
import Mapbox from "./components/Mapbox/Mapbox";
import AlertSystem from "./components/AlertSystem/AlertSystem";
import RadioBrowse from "./components/RadioBrowse/RadioBrowse";
import RadioStations from "./components/RadioBrowse/RadioStations";
import VideoView from "./components/VideoView/VideoView";
import Browse from "./components/Browse/Browse";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfService from "./components/pages/TermsOfService";
import Disclaimer from "./components/pages/Disclaimer";
import Contact from "./components/pages/Contact";
import Changelog from "./components/pages/Changelog";
import LiveAlerts from "./components/pages/LiveAlerts/LiveAlerts";
import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import CameraRequest from "./components/pages/CameraRequest";
import AudioPlayer from "./components/AudioPlayer/AudioPlayer";
import AdminLogin from "./components/Admin/AdminLogin";
import AdminDashboard from "./components/Admin/AdminDashboard";
import StreamCheckerPage from "./components/Admin/StreamChecker/StreamChecker";
import StreamCreator from "./components/Admin/StreamCreator/StreamCreator";
import CountryEditor from "./components/Admin/CountryProfiles/CountryProfiles";
import CountryDetails from "./components/CountryDetails/CountryDetails";
import HowToGetVerified from "./components/pages/HowToGetVerified";
import useWindowSize from "./hooks/useWindowSize";
import { ToastContainer } from "react-toastify";
import AdminPanel from "./components/Admin/AdminPanel/AdminPanel";
import "./App.css";
import ConsentPopup from "./components/ConsentPopup/ConsentPopup";
function App() {
  // Tracks if we are currently showing the "reload" loader
  const [showReloadLoader, setShowReloadLoader] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [videoDetails, setVideoDetails] = useState(null);
  const [channelDetails, setChannelDetails] = useState(null);
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const rainViewerCleanupRef = useRef(null);
  const location = useLocation();
  const [isMiniPlayerActive, setIsMiniPlayerActive] = useState(false);
  const [miniPlayerFeature, setMiniPlayerFeature] = useState(null);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const { isAdminAuthenticated } = useContext(AdminContext);
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const [mapState, setMapState] = useState({
    center: [-98.5795, 39.8283],
    zoom: 3,
    pitch: 0,
    bearing: 0,
  });



  window.logoUrl = "/Earf-E.png";

  useEffect(() => {
    // Check if this navigation was a refresh (reload)
    const [navEntry] = performance.getEntriesByType("navigation");
    // Possible types are "reload", "navigate", "back_forward", or "prerender"
    if (navEntry && navEntry.type === "reload") {
      // If user did a full page reload, show the loader
      setShowReloadLoader(true);

      // Hide the loader after 1 second (adjust as needed)
      const timer = setTimeout(() => {
        setShowReloadLoader(false);
      }, 2500);

      // Cleanup if component unmounts
      return () => clearTimeout(timer);
    }
  }, []);



  useEffect(() => {
    setIsCollapsed(isMobile);
  }, [isMobile]);


  useEffect(() => {
    ReactGA.initialize("G-SHLXRDHHQ6");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const handleCollapseToggle = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const handleFeatureClickFromMap = (feature) => {
    if (isMiniPlayerActive) {
      setIsMiniPlayerActive(false);
      setMiniPlayerFeature(null);
    }

    const currentMapState = {
      center: mapRef.current.getCenter().toArray(),
      zoom: mapRef.current.getZoom(),
      pitch: mapRef.current.getPitch(),
      bearing: mapRef.current.getBearing(),
    };

    setMapState(currentMapState);
    setSelectedFeature(feature);
    navigate(
      `/browse/${encodeURIComponent(
        feature.properties.tag2.toLowerCase().replace(/\s+/g, "-")
      )}/stream/${feature.properties.videoId}`
    );
  };

  const handleBackClick = () => {
    if (selectedFeature) {
      setIsMiniPlayerActive(true);
      setMiniPlayerFeature(selectedFeature);
    }
    setSelectedFeature(null);
    setVideoDetails(null);
    setChannelDetails(null);
    navigate("/explore");
  };

  const handleWildfireLocationSelect = (lat, lng, zoom) => {
    navigate("/explore");
    setSelectedLocation({ lat, lng, zoom });
  };

  const handleBrowseBackClick = () => {
    setIsMiniPlayerActive(true);
    navigate(-1);
  };

  const initializeRainViewer = (mapRef) => {
    console.log("RainViewer initialized!");
  };

  const handleCloseSidebar = () => {
    setIsCollapsed(true);
  };
  // If the reload loader should be shown, show a fullscreen overlay
  if (showReloadLoader) {
    return (
      <div className="reload-loader-overlay">
        <div className="reload-loader-content">
          <img
            src="/Earf-E.png"
            alt="Earf loading"
            className="reload-loader-image"
          />
        </div>

        {/* New Footer Section */}
        <footer className="reload-loader-footer">
          <h1 className="reload-loader-earf-text">EARF</h1>
          <div className="reload-footer-content">
            <p>
              Is your stream on{" "}
              <Link to="/camera-request" className="earf-highlight">
                EARF
              </Link>
              ? Want to get verified?{" "}
              <img
                src="/partner.png"
                alt="Verified Partner"
                aria-label="Verified Partner"
                className="tick-icon"
              />{" "}
              <Link to="/how-to-get-verified" className="loading-footer-link">
                {" "}
                Learn How
              </Link>
            </p>
          </div>
        </footer>
      </div>
    );
  }

  return (
    <AdminProvider>
      <PlayerProvider>
        <div className={`app-layout ${isMobile ? "mobile" : ""}`}>
          <Header onCollapseToggle={handleCollapseToggle} />
          <div className="body-layout">
            {isMobile ? (
              <MobileSidebar
                open={!isCollapsed}
                collapsed={isCollapsed}
                onToggle={handleCollapseToggle}
                handleCloseSidebar={handleCloseSidebar}
              />
            ) : (
              <Sidebar collapsed={isCollapsed} />
            )}

            <div
              className={`main-content ${
                isCollapsed ? "collapsed" : "expanded"
              }`}
            >
              <ConsentPopup />
              <AudioPlayer />
              <AlertSystem />
              <AdminPanel isAdminAuthenticated={isAdminAuthenticated} />
              <ToastContainer
                autoClose={5000} // Automatically close toasts after 5 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Older toasts show on top
                closeOnClick // Close on click
                pauseOnHover // Pause when hovered
                draggable // Allow dragging to dismiss
                theme="dark" // Set theme to dark
              />
              <Routes>
                <Route
                  path="/explore"
                  element={
                    <Mapbox
                      isCollapsed={isCollapsed}
                      selectedLocation={selectedLocation}
                      onFeatureClick={handleFeatureClickFromMap}
                      mapState={mapState}
                      setMapState={setMapState}
                      setMapRef={(map) => {
                        mapRef.current = map;
                      }}
                    />
                  }
                />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/stream/*" element={<NotFound />} />
                <Route path="/help-support" element={<Contact />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/changelog" element={<Changelog />} />
                <Route path="/camera-request" element={<CameraRequest />} />
                <Route path="/radio" element={<RadioBrowse />} />
                <Route path="/radio/:country" element={<RadioStations />} />
                <Route
                  path="/how-to-get-verified"
                  element={<HowToGetVerified />}
                />
                <Route
                  path="/live-alerts/*"
                  element={
                    <LiveAlerts
                      handleWildfireLocationSelect={
                        handleWildfireLocationSelect
                      }
                    />
                  }
                />
                <Route
                  path="/country/:countryName"
                  element={<CountryDetails />}
                />
                <Route path="/country" element={<Navigate to="/browse" />} />
                <Route path="/country/" element={<Navigate to="/browse" />} />
                <Route
                  path="/browse"
                  element={
                    <Browse
                      setIsMiniPlayerActive={setIsMiniPlayerActive}
                      setMiniPlayerFeature={setMiniPlayerFeature}
                      handleBrowseBackClick={handleBrowseBackClick}
                    />
                  }
                />
                <Route
                  path="/browse/:tag"
                  element={
                    <Browse
                      setIsMiniPlayerActive={setIsMiniPlayerActive}
                      setMiniPlayerFeature={setMiniPlayerFeature}
                      handleBrowseBackClick={handleBrowseBackClick}
                    />
                  }
                />
                <Route path="/stream" element={<Navigate to="/browse" />} />
                <Route
                  path="/browse/:selectedTag/stream/:videoId"
                  element={
                    <VideoView
                      feature={selectedFeature}
                      videoDetails={videoDetails}
                      channelDetails={channelDetails}
                      handleBackClick={handleBackClick}
                      setMapState={setMapState}
                      setIsMiniPlayerActive={setIsMiniPlayerActive}
                      setMiniPlayerFeature={setMiniPlayerFeature}
                    />
                  }
                />
                <Route path="/admin" element={<Navigate to="/admin/login" />} />
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route
                  path="/admin/feature-editor"
                  element={<StreamCheckerPage />}
                />
                <Route
                  path="/admin/feature-creator"
                  element={<StreamCreator />}
                />
                <Route
                  path="/admin/country-profiles"
                  element={<CountryEditor />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </PlayerProvider>
    </AdminProvider>
  );
}
(function() {
  const headerStyle = "color: red; font-size: 24px; font-weight: bold;";
  const messageStyle = "color: white; font-size: 14px;";
  const linkStyle = "color: white; font-size: 14px; text-decoration: underline; cursor: pointer;";
  
  console.log("%cSTOP!", headerStyle);
  console.log("%cUnauthorized access to this console is strictly prohibited.", messageStyle);
  console.log("%cBy accessing or using this console, you agree to be bound by our legally binding Disclaimer and Terms of Service.", messageStyle);
  console.log("%chttps://earf.tv/disclaimer", linkStyle);
  console.log("%chttps://earf.tv/terms-of-service", linkStyle);
  console.log("%cIf you are not an authorized developer, please exit immediately. Unauthorized use may result in legal action.", messageStyle);
})();

export default App;
