// src/ManualDescription/ManualDescription.js
import React from "react";
import "./ManualDescription.css";

const ManualDescription = ({ manualDescription }) => {
  return (
    <div className="manual-description-container">
      <p className="manual-description">{manualDescription}</p>
    </div>
  );
};

export default ManualDescription;
