import React from "react";
import { useNavigate } from "react-router-dom";
import "./Disclaimer.css"; // CSS file for styling
import { Helmet } from "react-helmet-async";

const Disclaimer = () => {
  const navigate = useNavigate();

  return (
    <div className="disclaimer-container">
      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>DMCA & Copyright Disclaimer | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Read Earf's DMCA & Copyright Disclaimer to understand our process for handling copyright infringement claims and protecting intellectual property rights on our platforms."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/disclaimer" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="DMCA & Copyright Disclaimer | Earf"
        />
        <meta
          property="og:description"
          content="Learn how Earf manages copyright infringement claims under the DMCA and our commitment to protecting intellectual property rights on our live-streaming platforms."
        />
        <meta property="og:image" content="https://earf.tv/Earf-E.png" />
        <meta property="og:url" content="https://earf.tv/disclaimer" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="929645909242200" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="DMCA & Copyright Disclaimer | Earf"
        />
        <meta
          name="twitter:description"
          content="Understand how Earf handles copyright infringement claims under the DMCA and protects intellectual property rights across our platforms."
        />
        <meta name="twitter:image" content="https://earf.tv/Earf-E.png" />
        <meta
          name="twitter:image:alt"
          content="Earf DMCA and Copyright Disclaimer"
        />
      </Helmet>

      {/* Header with Back Button (fixed at the top) */}
      <div className="header-container">
        <h1 className="disclaimer-header">
          <img src="/Earf-E.png" alt="Earf Logo" className="contact-logo" />{" "}
          DMCA & Copyright Disclaimer
        </h1>
      </div>

      {/* Scrollable content section */}
      <div className="disclaimer-content">
        <p style={{ fontSize: "16px" }}>
          <strong>Effective Date: </strong> March 21, 2025
        </p>

        {/* DMCA & Copyright Policy */}
        <p>
          Earf, through its platform <strong>Earf.TV </strong>, previously known
          as <strong>Interactive.Earth </strong>, is committed to respecting the
          intellectual property rights of others. This DMCA & Copyright Policy
          governs the procedures for handling copyright infringement claims on
          the Website.
        </p>

        <p>
          References to <strong>"Earf" "we," "us,"</strong> and{" "}
          <strong>"our"</strong> refer to <strong>Earf</strong>, including but
          not limited to <strong>Earf.TV</strong>,{" "}
          <strong>Interactive.Earth</strong>, and all associated subdomains,
          websites, and services created and operated by Earf. This includes,
          but is not limited to, <strong>Remote Vehicle Estimating</strong>{" "}
          (remotevehicleestimating.com.au) and <strong>Encra</strong>{" "}
          (encra.me). By accessing or using any of these websites or services,
          you agree to be bound by our DMCA & Copyright Policy.
        </p>

        <p>
          By accessing or using <strong>Earf </strong> (the "Website"),
          including any domains or subdomains associated with or created by{" "}
          <strong>Earf</strong>, you confirm that you have read, understood, and
          agreed to this DMCA & Copyright Policy. If you do not agree, please
          discontinue your use of the Website immediately.
        </p>
        <p>
          Earf complies with the Digital Millennium Copyright Act of 1998
          (DMCA). If you own a copyright or have authority to act on behalf of a
          copyright owner and want to report a claim that Earf or a third party
          is infringing on your material through Earf services, please send a
          notice that meets the minimum requirements of the DMCA to{" "}
          <a href="mailto:legal@earf.tv">legal@earf.tv</a>.
        </p>

        <h3>Protection of Intellectual Property</h3>

        <p>
          All proprietary content, design, layout, graphics, and code created by
          or for Earf, including Earf.TV, Interactive.Earth, Remote Vehicle
          Estimating and Encra are protected by intellectual property laws and
          are the exclusive property of Earf. This protection applies to all
          elements of the websites and services not covered by third-party
          rights, such as external videos or radio streams.
        </p>

        <p>
          Any unauthorized reproduction, copying, modification, distribution, or
          exploitation of the intellectual property owned by Earf, whether found
          on Earf.TV, Interactive.Earth, Remote Vehicle Estimating and Encra or
          any of their Domains or subdomains, is expressly prohibited. Violators
          will face swift legal action. This includes, but is not limited to,
          the unauthorized use of Earf's website designs, code, logos, graphics,
          and other proprietary materials.
        </p>

        <p>
          Earf reserves the right to pursue all available legal remedies against
          individuals or entities that infringe on its intellectual property
          rights. This includes immediate legal proceedings for injunctions,
          claims for damages, and the pursuit of civil or criminal penalties
          where applicable. Any individual or entity found in violation may be
          liable for significant monetary damages, including legal fees, and may
          face additional penalties under intellectual property law.
        </p>

        <p>
          Earf’s intellectual property rights will be enforced to their fullest
          extent, and any infringement will be prosecuted in the appropriate
          legal jurisdiction. We take all violations seriously and will take
          necessary legal measures to protect our rights.
        </p>

        <h3>Automatic Copyright Protection in Australia</h3>

        <p>
          Under <strong>Australian copyright law </strong>, creators
          automatically receive copyright protection the moment their work is
          recorded in a tangible form—whether it is written, digitally stored,
          or otherwise documented.{" "}
          <strong>Registration is not required </strong> to obtain copyright
          protection in Australia.
        </p>

        <p>
          <strong>Earf</strong>, including all original content, source code,
          branding, domains, subdomains, and other proprietary elements
          associated with Earf and its affiliated websites, is protected under
          the <strong>Copyright Act 1968 (Cth)</strong>. This protection applies
          to all domains and subdomains listed above, and includes, but is not
          limited to:
        </p>

        <ul>
          <li>Website designs, layouts, and structures</li>
          <li>Search Engine Optimization and metadata</li>
          <li>Logos, brandings, and graphics</li>
          <li>Original text and written materials</li>
          <li>Proprietary code and software implementations</li>
        </ul>

        <p>
          Any{" "}
          <strong>
            unauthorized reproduction, modification, distribution, or commercial
            use
          </strong>{" "}
          of Earf’s intellectual property{" "}
          <strong>without explicit permission </strong> is strictly prohibited.
          Copyright infringement will result in{" "}
          <strong>immediate legal action </strong>, including but not limited to{" "}
          <strong>
            cease-and-desist orders, monetary damages, and further penalties
            under Australian intellectual property laws
          </strong>
          .
        </p>
        <p>
          Any individual or entity found to be copying, imitating, or altering
          the content or structure of our websites without explicit written
          permission will be issued a formal notice and given
          <strong>7 days to comply and remove the infringing content</strong>.
          Failure to comply within this timeframe will result in immediate
          escalation, including but not limited to:
        </p>

        <ul>
          <li>
            Formal complaints lodged with regulatory bodies, domain authorities,
            and hosting providers
          </li>
          <li>
            legal action for copyright infringement, deceptive conduct, and
            breach of intellectual property laws
          </li>
          <li>
            Claims for financial damages, including but not limited to costs
            incurred in investigating and addressing the infringement, loss of
            production time, disruption to services, and any loss of revenue or
            opportunities
          </li>
          <li>Public disclosure of the infringement</li>
        </ul>

        <p>
          Action will be taken to the fullest extent permitted under Australian
          law and international copyright law.
        </p>

        <p>
          If you believe that content on Earf.TV infringes your copyright,
          please follow the <strong>DMCA Notice Submission Process </strong>{" "}
          outlined in this document. If you require legal assistance or
          clarification regarding Earf’s intellectual property rights, contact{" "}
          <a href="mailto:legal@earf.tv">legal@earf.tv</a>.
        </p>

        <h3>Third-Party Content Liability</h3>

        <p>
          Earf provides a platform for displaying third-party content, including
          but not limited to YouTube live streams, embedded media, and external
          video or radio sources. We do not own, control, or verify third-party
          content and disclaim any liability for copyright infringement related
          to such content.
        </p>

        <p>
          If you believe that third-party content displayed on Earf.TV infringes
          your copyright, you must pursue a takedown request directly with the
          <strong> content hosting provider </strong> (e.g., YouTube, Twitch, or
          other video services). Earf will comply with valid DMCA takedown
          requests but is not responsible for the content hosted on external
          platforms.
        </p>

        <h3>DMCA Compliance & Safe Harbor</h3>

        <p>
          Earf complies with the{" "}
          <strong>Digital Millennium Copyright Act (DMCA) Section 512</strong>,
          which provides "safe harbor" protections for online service providers
          against liability for user-generated content, provided we follow
          appropriate takedown procedures.
        </p>

        <p>
          In accordance with <strong>DMCA 512(c)</strong>, Earf.TV will:
        </p>
        <ul>
          <li>
            Respond promptly to{" "}
            <strong>valid copyright takedown requests </strong>.
          </li>
          <li>
            Remove or disable access to allegedly infringing content{" "}
            <strong>upon proper notification </strong>.
          </li>
          <li>
            Allow users to file a <strong>counter-notice </strong> if they
            believe their content was removed in error.
          </li>
          <li>
            Terminate access for <strong>repeat infringers </strong> in
            accordance with our Repeat Infringer Policy.
          </li>
        </ul>

        <h3>Submitting a DMCA Notice</h3>

        <p>
          If you are a copyright owner or authorized agent and believe that
          material available on Earf, including Earf.TV or Interactive.Earth,
          infringes on your copyright, you may submit a DMCA notification by
          providing our designated copyright agent with the following
          information in writing:
        </p>

        <ul>
          <li>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of the copyrighted work.
          </li>
          <li>
            Identification of the copyrighted work claimed to have been
            infringed, or, if multiple works are covered by a single
            notification, a representative list of such works.
          </li>
          <li>
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled, and information reasonably
            sufficient to permit us to locate the material.
          </li>
          <li>
            Information reasonably sufficient to permit us to contact you, such
            as an address, telephone number, and email address.
          </li>
          <li>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law.
          </li>
          <li>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that you are authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed.
          </li>
        </ul>

        <p>
          Please send your DMCA notice to{" "}
          <a href="mailto:legal@earf.tv">legal@earf.tv</a>.
        </p>

        <h3>Submitting a DMCA Counter-Notice</h3>

        <p>
          If you believe that material has been removed or disabled by mistake
          or misidentification, you may submit a counter-notification in writing
          to our designated agent. Your counter-notification must include the
          following:
        </p>

        <ul>
          <li>Your physical or electronic signature.</li>
          <li>
            Identification of the material that has been removed or to which
            access has been disabled and the location at which the material
            appeared before it was removed or access was disabled.
          </li>
          <li>
            A statement under penalty of perjury that you have a good faith
            belief that the material was removed or disabled as a result of
            mistake or misidentification.
          </li>
          <li>Your name, address, telephone number, and email address.</li>
        </ul>

        <p>
          Please send your DMCA counter-notice to{" "}
          <a href="mailto:legal@earf.tv">legal@earf.tv</a>.
        </p>

        <h3>Trademark Takedown Requests</h3>

        <p>
          If you believe that content on Earf.TV or Interactive.Earth infringes
          on your trademark rights, please provide the following information to{" "}
          <a href="mailto:legal@earf.tv">legal@earf.tv</a>:
        </p>

        <ul>
          <li>
            Details of the original trademark (including a description if it's a
            logo).
          </li>
          <li>List of countries where the trademark is registered.</li>
          <li>Registration number(s) of the trademark.</li>
          <li>
            Scanned copy of the trademark registration certificate(s) or
            screenshot of the registration on the website or database of the
            applicable national intellectual property offices.
          </li>
          <li>
            A declaration stating that you have a good-faith belief that the
            reported use is not authorized by the intellectual property rights
            owner, its agent, or the law.
          </li>
        </ul>

        <h3>Repeat Infringer Policy</h3>

        <p>
          We maintain a strict policy against repeat infringers. We reserve the
          right to suspend or terminate accounts and/or block IP addresses of
          users who repeatedly violate the intellectual property rights of
          others. This action is taken in accordance with our policies and at
          our sole discretion.
        </p>
        <h3>Repeat Infringer Policy & Enforcement</h3>

        <p>
          Earf.TV enforces a strict policy against users who repeatedly violate
          copyright laws. Users who receive multiple valid copyright takedown
          notices will be <strong>subject to enforcement actions </strong>,
          including:
        </p>

        <ul>
          <li>
            <strong style={{ color: "var(--earf-blue)" }}>
              Non-Verified Users:
            </strong>{" "}
            If you are{" "}
            <strong style={{ color: "var(--earf-blue)" }}>
              not a verified member
            </strong>
            , your{" "}
            <strong style={{ color: "var(--earf-blue)" }}>
              channel and any streams will be immediately removed or taken
              offline on Earf
            </strong>{" "}
            upon receiving a copyright claim.
            <ul>
              <li>
                No warnings or chances will be given to non-verified users.
              </li>
              <li>
                Your content will remain offline{" "}
                <strong style={{ color: "var(--earf-blue)" }}>
                  until verification of the copyright infringement claim is
                  completed
                </strong>
                .
              </li>
            </ul>
          </li>
          <li>
            <strong>First Offense (Verified Users Only): </strong> Formal
            warning issued via Email/Social Media accounts (if applicable) or
            on-screen notification. Potential temporary restriction from
            specific features.
          </li>
          <li>
            <strong>Second Offense: </strong> Temporary suspension of access to
            Earf.TV for up to{" "}
            <strong style={{ color: "var(--earf-blue)" }}> 1 year</strong>, with
            a notification about the consequences of further violations.
          </li>
          <li>
            <strong>Third Offense: </strong> Permanent termination of access,
            including:
            <ul>
              <li>
                <strong>
                  Banned from Earf.TV without appeal for up to{" "}
                  <strong style={{ color: "var(--earf-blue)" }}>
                    {" "}
                    5 years.
                  </strong>
                </strong>
                <ul>
                  <li>
                    Any attempt to bypass the ban will result in{" "}
                    <strong>immediate detection and enforcement. </strong>
                  </li>
                  <li>
                    Any associated <strong>YouTube channels </strong> will also
                    be
                    <strong> blocked from being featured on Earf. </strong>
                  </li>
                  <li>
                    If a banned user operated cameras on Earf.TV, any streams
                    that were or{" "}
                    <strong>
                      are potentially related to the banned account
                    </strong>
                    will be <strong>removed or permanently disallowed </strong>{" "}
                    from appearing on the platform for the entire ban period.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Unban Conditions: </strong> If unbanned after the ban
                period expires, the user will be required to{" "}
                <strong style={{ color: "var(--earf-blue)" }}>
                  pay a reinstatement fee{" "}
                </strong>
                before regaining access to and having the channel and streams
                reinstated on Earf.TV
              </li>
            </ul>
          </li>

          <li>
            <strong>Fourth Offense (if bypassed): </strong> Permanent denial of
            service:
            <ul>
              <li>
                <strong>ISP Notification: </strong> If a user continues to
                violate terms despite multiple bans, their{" "}
                <strong>
                  Internet Service Provider (ISP) will be contacted
                </strong>{" "}
                with details of repeated abuse.
              </li>
              <li>
                <strong>Potential Law Enforcement Involvement: </strong> If the
                violations involve illegal activities (e.g., harassment,
                threats, fraud, or unauthorized access attempts), we may
                escalate the case to{" "}
                <strong>law enforcement authorities </strong>.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          Earf.TV reserves the right to <strong>immediately terminate </strong>{" "}
          access for any user or entity engaging in{" "}
          <strong>egregious copyright violations </strong>, including mass
          infringement, piracy, or repeated abuse of our services for illegal
          content distribution.
        </p>
        <p>
          If you believe your content was removed due to an incorrect or
          fraudulent claim, you may file a <strong>formal appeal </strong> with{" "}
          <a href="mailto:legal@earf.tv">legal@earf.tv</a> for review.
        </p>

        <p>
          For any inquiries regarding copyright or trademark matters, please
          contact us at <a href="mailto:legal@earf.tv">legal@earf.tv</a>.
        </p>

        <p className="footer-text">
          © {new Date().getFullYear()} Earf.TV. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
