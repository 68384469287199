import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Sidebar.module.css';
import Loader from '../Loader/Loader';
import { FaDiscord } from "react-icons/fa"; // Import a Discord icon for the footer

const Sidebar = ({ collapsed }) => {
  const [popularLocations, setPopularLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const location = useLocation();
  const tag = location.state?.tag;

  const browsePath =
    location.pathname.includes('/stream/') && tag
      ? `/browse/${encodeURIComponent(tag)}`
      : '/browse';

  useEffect(() => {
    const fetchPopularLocations = async () => {
      try {
        const response = await fetch('https://server.earf.tv/popular-countries', {
          method: 'GET',
          headers: {
            'x-api-key': process.env.REACT_APP_EARF_API_KEY,
            'Content-Type': 'application/json',
            'X-Request-Source': 'Popular-Countries',
          },
        });

        if (!response.ok) {
          console.error('Failed to fetch popular locations');
          return;
        }

        const data = await response.json();
        setPopularLocations(data.topCountries || []);
      } catch (error) {
        console.error('Error fetching popular locations:', error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };

    fetchPopularLocations();
  }, []);

  const hasAlert = (location) =>
    location?.profile?.properties?.alert && location.profile.properties.alert.trim() !== '';

  return (
    <div className={`${styles.sidebar} ${collapsed ? styles.collapsed : ''}`} id="sidebar">
      <div className={styles['sidebar-nav']}>
        <Link to={browsePath} className={`${styles['sidebar-menu-item']}`} id="browse-button">
          <i className="fa-solid fa-briefcase"></i>
          {!collapsed && <span>Browse</span>}
        </Link>
        <Link to="/radio" className={styles['sidebar-menu-item']} id="radio-button">
          <i className="fa-solid fa-radio"></i>
          {!collapsed && <span>Radio</span>}
        </Link>
        <Link to="/explore" className={styles['sidebar-menu-item']} id="map-button">
          <i className="fa-solid fa-map"></i>
          {!collapsed && <span>Map</span>}
        </Link>
      </div>

      <div className={styles['section-divider']}></div>

      <div className={`${styles['popular-section']} ${collapsed ? styles.collapsed : ''}`}>
        <div className={styles['left-section']}>
          {!collapsed && !isLoading && <h2>Popular Locations</h2>}
        </div>
        <div className={`${styles['collapsed-header']} ${collapsed ? styles.show : ''}`}>
          <i className="fas fa-heart"></i>
        </div>
      </div>

      <div className={styles['sidebar-items']} id="sidebar1">
        {isLoading ? (
          <div className={styles.skeletonloader}>
            <div className={styles.skeletonHeader}></div>
            <div className={styles.skeletonItem}></div>
            <div className={styles.skeletonItem}></div>
            <div className={styles.skeletonItem}></div>
            <div className={styles.skeletonItem}></div>
            <div className={styles.skeletonItem}></div>

          </div>
        ) : (
          popularLocations.length > 0 ? (
            popularLocations.map((location) => {
              const hasAlertFlag = hasAlert(location);
              const countryCode = location.profile.properties.iso_code.toLowerCase();
              const flagUrl = `https://flagcdn.com/w320/${countryCode}.png`;

              return (
                <Link
                  key={location.country}
                  to={`/browse/${encodeURIComponent(location.country)}`}
                  className={styles['country-item']}
                >
                  <img
                    src={flagUrl}
                    alt={`${location.country} Flag`}
                    className={styles['flag-icon']}
                  />
                  <div className={styles['browsetext-container']}>
                    <span className={styles['country-name']}>{location.country}</span>
                    <span className={styles['region-name']}>
                      {location.profile.properties.subregion || 'Unknown Region'}
                    </span>
                  </div>

                  {!collapsed && hasAlertFlag && (
                    <div className={styles['alert-icon-container']}>
                      <div className={styles['alert-icon']}>
                        <i className="fas fa-exclamation-triangle"></i>
                      </div>
                    </div>
                  )}
                </Link>
              );
            })
          ) : (
            <Loader />
          )
        )}
      </div>

      {/* Sidebar Footer */}
      <div className={styles.sidebarFooter}>
        <Link
          to="https://discord.gg/6NqN2GfRyq"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.footerLink}
        >
          <FaDiscord className={styles.footerIcon} />
          {!collapsed && <span>Join the Community</span>}
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
