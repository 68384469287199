import React from "react";
import styles from "./Loader.module.css"; // Import the CSS Module

const Loader = () => (
  <div className={styles["loader-wrapper"]}>
    <div className={styles.loader}></div>
  </div>
);

export default Loader;
